import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { FilterType } from "./filter";

@Injectable({
    providedIn: "root",
})
export class FilterService {
    public filters$: Observable<Set<FilterType>>;

    // init filter are empty
    private readonly filtersSource = new BehaviorSubject<Set<FilterType>>(new Set());

    public constructor() {
        this.filters$ = this.filtersSource.asObservable();
    }

    public toggleFilter(filter: FilterType) {
        const newFilters = new Set(this.filtersSource.value);

        if (newFilters.has(filter)) {
            newFilters.delete(filter);
        } else {
            newFilters.add(filter);
        }

        this.filtersSource.next(newFilters);
    }

    public clear() {
        this.filtersSource.next(new Set());
    }
}
