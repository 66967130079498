import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { ConfigService } from "@app/config.service";
import { Theme } from "@models/theme/theme";

@Injectable({ providedIn: "root" })
export class ThemeService {
    public theme$: Observable<Theme>;
    private readonly themeSource: BehaviorSubject<Theme>;

    public constructor(private readonly config: ConfigService) {
        this.themeSource = new BehaviorSubject(this.config.theme);
        this.theme$ = this.themeSource.asObservable();
    }

    public toggleTheme(): void {
        this.themeSource.next(this.themeSource.value === Theme.DEFAULT ? Theme.MARCOVASCO : Theme.DEFAULT);
    }
}
