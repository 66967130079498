import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { ConfigService } from "@app/config.service";

@Injectable({ providedIn: "root" })
export class ScreensaverService {
    // all times are in minute
    public loginTime$: Observable<number>;
    public tripTime$: Observable<number>;
    private readonly loginTimeSource: BehaviorSubject<number>;
    private readonly tripTimeSource: BehaviorSubject<number>;

    public constructor(private readonly config: ConfigService) {
        this.loginTimeSource = new BehaviorSubject<number>(this.config.screensaverTimeLoginMinute);
        this.tripTimeSource = new BehaviorSubject<number>(this.config.screensaverTimeTripMinute);
        this.loginTime$ = this.loginTimeSource.asObservable();
        this.tripTime$ = this.tripTimeSource.asObservable();
    }

    public setLoginTime(time: number) {
        if (this.loginTimeSource.value !== time) {
            this.loginTimeSource.next(time);
        }
    }

    public setTripTime(time: number) {
        if (this.tripTimeSource.value !== time) {
            this.tripTimeSource.next(time);
        }
    }
}
