import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SwiperModule } from "ngx-swiper-wrapper";

import { FullscreenItemComponent } from "./fullscreen-item/fullscreen-item.component";
import { FullscreenComponent } from "./fullscreen/fullscreen.component";

@NgModule({
    declarations: [FullscreenComponent, FullscreenItemComponent],
    exports: [FullscreenComponent, FullscreenItemComponent],
    imports: [CommonModule, SwiperModule],
})
export class FullscreenModule {}
