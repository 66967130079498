import { Injectable } from "@angular/core";

import { INavigationHistoryGetDto, ISearchHistoryGetDto, IShopGetDto, IUserGetDto } from "@dtos";
import { IPoi } from "@models/poi/poi";
import { PoiService } from "@models/poi/poi.service";
import { initUser, IUser } from "@models/user/user";

@Injectable()
export class UserMapper {
    public pois: Map<number, IPoi[]>;

    public constructor(private readonly poiService: PoiService) {
        this.poiService.pois$.subscribe((pois) => (this.pois = pois));
    }

    public mapGetUser = (userDto: IUserGetDto): IUser => {
        return initUser({
            avatarUrl: userDto.avatarUrl,
            id: userDto.id,
            name: `${userDto.firstName} ${userDto.lastName}`,
        });
    };

    public mapGetShop = (shopDto: IShopGetDto): IUserGetDto[] => {
        return shopDto.users;
    };

    public mapGetNavigationHistory = (navigationHistories: INavigationHistoryGetDto[], countryId: number): IPoi[] => {
        // if the navigationHistory wasn't found in the pois list : ignore it
        return navigationHistories
            .sort(sortNavigationHistory)
            .map((nh) => this.pois.get(countryId)?.find((poi) => poi.brickId === nh.brickId))
            .filter((poi): poi is IPoi => poi != null);
    };

    public mapGetSearchHistory = (searchHistories: ISearchHistoryGetDto[]): string[] => {
        return searchHistories.sort(sortSearchHistory).map((searchHistory) => searchHistory.search);
    };
}

export function sortNavigationHistory(h1: INavigationHistoryGetDto, h2: INavigationHistoryGetDto): number {
    return h2.nbNavigations - h1.nbNavigations;
}

export function sortSearchHistory(h1: ISearchHistoryGetDto, h2: ISearchHistoryGetDto): number {
    return h2.nbSearches - h1.nbSearches;
}
