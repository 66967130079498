import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ApiBindingModule } from "@api-binding/api-binding.module";
import { ApiStrapiModule } from "@api-strapi/api-strapi.module";
import { FullscreenModule } from "@core/fullscreen/fullscreen.module";
import { KeyboardModule } from "@core/keyboard/keyboard.module";
import { ModalModule } from "@core/modal/modal.module";
import { PopinModule } from "@core/popin/popin.module";
import { MaterialModule } from "@shared";
import { InteractionModule } from "./interaction/interaction.module";
import { PlayerModule } from "./player/player.module";

@NgModule({
    declarations: [],
    exports: [KeyboardModule, PopinModule, FullscreenModule, PlayerModule],
    imports: [
        CommonModule,
        ApiBindingModule,
        ApiStrapiModule,
        FullscreenModule,
        KeyboardModule,
        InteractionModule,
        MaterialModule,
        ModalModule,
        PopinModule,
        PlayerModule,
    ],
})
export class CoreModule {}
