import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";

export interface IPopinData {
    cancellation: string;
    confirmation: string;
    state: boolean;
    title: string;
}

export const initialPopin: IPopinData = {
    cancellation: `Continuer mon exploration`,
    confirmation: `Revenir à l'accueil`,
    state: false,
    title: `Vous êtes sur le point de revenir à la page d’accueil`,
};

@Injectable({ providedIn: "root" })
export class PopinService {
    public popinData$: Observable<IPopinData>;
    public popinAction$: Observable<boolean | null>;
    private readonly popinDataSource = new BehaviorSubject<IPopinData>(initialPopin);
    private readonly popinActionSource = new Subject<boolean>();

    public constructor() {
        this.popinData$ = this.popinDataSource.asObservable();
        this.popinAction$ = this.popinActionSource.asObservable();
    }

    public confirmAction() {
        this.popinActionSource.next(true);
    }

    public cancelAction() {
        this.popinActionSource.next(false);
    }

    public async showPopin(popinData: IPopinData): Promise<boolean> {
        popinData.state = true;
        this.popinDataSource.next(popinData);

        return new Promise<boolean>((resolve) => {
            this.popinAction$.pipe(take(1)).subscribe((action) => {
                if (action != null) {
                    this.hidePopin();
                    resolve(action);
                }
            });
        });
    }

    public hidePopin() {
        this.popinDataSource.next({ ...this.popinDataSource.value, state: false });
    }
}
