import { environment } from "../../../environments/environment";

export class ExperienceDefaultModel {
    public id: number;

    [key: string]: any;

    constructor(data?: any) {
        this.defaultConstructor(data);
    }

    public defaultConstructor(data?: any) {
        const self = this;

        if (undefined !== data && null !== data) {
            for (const prop in data) {
                if (typeof data[prop] !== "function") {
                    self[prop] = data[prop];
                }
            }
        }
    }

    public getPublicUrl() {
        return `${environment.strapiPublicUrl}${this.url}`;
    }
}

export class ExperienceImage extends ExperienceDefaultModel {
    public url: string;
    public name: string;
    public alternativeText: string;
    public caption: string;
    public width: number;
    public height: number;

    constructor(data?: any) {
        super(data.attributes);
        this.id = data.id;
    }
}

export class ExperienceAsset extends ExperienceDefaultModel {
    public url: string;
    public name: string;
    public alternativeText: string;
    public caption: string;

    constructor(data?: any) {
        super(data.attributes);
        this.id = data.id;
    }
}

export class Experience {
    public image: ExperienceImage;
    public asset: ExperienceAsset;

    constructor(data: { image: any; asset: any }) {
        this.image = new ExperienceImage(data.image.data);
        this.asset = new ExperienceAsset(data.asset.data);
    }

    public getVideoUrl() {
        return this.asset.getPublicUrl();
    }

    public getPosterUrl() {
        return this.image.getPublicUrl();
    }
}
