import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { mapTo, tap } from "rxjs/operators";

import { environment } from "@environment";

import { UserRepository } from "@api-binding";
import { ICountry } from "@models/country/country";

import { areSameUser, IUser, setNavigationHistory, setSearchHistory, sortUsers } from "@models/user/user";

@Injectable({
    providedIn: "root",
})
export class UserService {
    public users$: Observable<IUser[]>;
    public currentUser$: Observable<IUser | null>;
    private readonly avatarPath = `${environment.moustacheApiUrl}/${environment.avatarAssetsPath}/`;
    private user: IUser;
    private readonly currentUserSource = new BehaviorSubject<IUser | null>(null);
    private readonly usersSource = new BehaviorSubject<IUser[]>([]);

    public constructor(private readonly userRepository: UserRepository) {
        this.currentUser$ = this.currentUserSource.asObservable();
        this.currentUser$.subscribe((u: IUser) => {
            if (u !== null) {
                this.user = u;
            }
        });
        this.users$ = this.usersSource.asObservable();
    }

    public getAvatarPath(): string {
        return this.avatarPath;
    }

    public selectUser(user: IUser | null): void {
        if (!areSameUser(user, this.currentUserSource.value)) {
            this.currentUserSource.next(user);
        }
    }

    public getCurrentUser(): IUser {
        return this.user;
    }

    public setUsers(users: IUser[]): void {
        this.usersSource.next(users.sort(sortUsers));
    }

    public async loadNavigationHistory(country: ICountry): Promise<null> {
        if (this.currentUserSource.value == null) {
            // shouldn't happen because the route is guarded
            console.error(`UserService - no user selected to fetch navigation history`);

            return new Promise((resolve) => resolve(null));
        }

        return this.userRepository
            .getNavigationHistory(this.currentUserSource.value.id, country.id)
            .pipe(
                tap((nh) => {
                    if (this.currentUserSource.value != null) {
                        // emit the updated user in the currentUser observable
                        this.currentUserSource.next(setNavigationHistory(this.currentUserSource.value, nh, country.id));
                    }
                }),
                mapTo(null),
            )
            .toPromise();
    }

    public async loadSearchHistory(country: ICountry): Promise<null> {
        if (this.currentUserSource.value == null) {
            // shouldn't happen because the route is guarded
            console.error(`UserService - no user selected to fetch search history`);

            return new Promise((resolve) => resolve(null));
        }

        return this.userRepository
            .getSearchHistory(this.currentUserSource.value.id, country.id)
            .pipe(
                tap((sh) => {
                    if (this.currentUserSource.value != null) {
                        // emit the updated user in the currentUser observable
                        this.currentUserSource.next(setSearchHistory(this.currentUserSource.value, sh, country.id));
                    }
                }),
                mapTo(null),
            )
            .toPromise();
    }

    public clear(): void {
        this.selectUser(null);
    }
}
