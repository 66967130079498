import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { ConfigService } from "@app/config.service";
import { IMapOptions } from "@map-lib/models";
import { IMapview, isSameMapview } from "@models/mapview/mapview";

@Injectable({ providedIn: "root" })
export class MapviewService {
    public mapview$: Observable<IMapview>;
    private readonly mapviewSource: BehaviorSubject<IMapview>;

    public constructor(private readonly config: ConfigService) {
        this.mapviewSource = new BehaviorSubject(this.getInitMapview());
        this.mapview$ = this.mapviewSource.asObservable();
    }

    public setMapview(mapview: IMapview): void {
        if (!isSameMapview(this.mapviewSource.value, mapview)) {
            this.mapviewSource.next(mapview);
        }
    }

    public getCurrentMapview(): IMapview {
        return { ...this.mapviewSource.value };
    }

    public getInitMapview(): IMapview {
        return getMapviewFromMapOptions(this.config.mapOptions);
    }
}

function getMapviewFromMapOptions(mapOptions: IMapOptions): IMapview {
    return {
        lat: mapOptions.center.lat(),
        lng: mapOptions.center.lng(),
        zoom: mapOptions.zoom,
    };
}
