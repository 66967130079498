import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SafeHtmlPipe } from "@shared";

import { PopinComponent } from "./popin/popin.component";

@NgModule({
    declarations: [PopinComponent, SafeHtmlPipe],
    exports: [PopinComponent, SafeHtmlPipe],
    imports: [CommonModule],
})
export class PopinModule {}
