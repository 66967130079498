import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ModalComponent } from "./modal/modal.component";

@NgModule({
    declarations: [ModalComponent],
    imports: [CommonModule, DragDropModule],
})
export class ModalModule {}
