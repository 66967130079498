import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { IPoi, isActivity, PoiType } from "@models/poi/poi";
import { PoiService } from "@models/poi/poi.service";

@Injectable({
    providedIn: "root",
})
export class FavoriteService {
    public favorites$: Observable<Map<number, IPoi>>;
    public favoritesList$: Observable<IPoi[]>;
    private readonly favoritesSource = new BehaviorSubject<Map<number, IPoi>>(new Map<number, IPoi>());

    public constructor(private readonly poiService: PoiService) {
        this.favorites$ = this.favoritesSource.asObservable();
        this.favoritesList$ = this.favorites$.pipe(map((favoritesMap) => Array.from(favoritesMap.values())));
    }

    public add(poi: IPoi): void {
        if (this.favoritesSource.value.has(poi.brickId)) {
            return;
        }

        const newFavorites = new Map(this.favoritesSource.value);
        newFavorites.set(poi.brickId, poi);

        this.favoritesSource.next(newFavorites);

        // if favorite is an activity and its place is not in favorites : add the place to favorites
        if (isActivity(poi)) {
            poi.placesIds.forEach((placeId) => {
                if (!this.favoritesSource.value.has(placeId)) {
                    const activityPlaces = this.poiService.getPlacesOfActivity(poi);
                    if (activityPlaces != null) {
                        activityPlaces.forEach((place) => {
                            this.add(place);
                        });
                    }
                }
            });
        }
    }

    public favoritesFromPlace(place: IPoi): IPoi[] {
        const favoritesMap = Array.from(this.favoritesSource.value);

        const favorites = favoritesMap
            .map((favoriteMap) => favoriteMap[1])
            .filter((poi) => poi.placesIds.includes(place.brickId) && poi.type !== PoiType.PLACE);

        return favorites;
    }

    public remove(brickId: number): void {
        if (!this.favoritesSource.value.has(brickId)) {
            return;
        }

        const newFavorites = new Map(this.favoritesSource.value);
        newFavorites.delete(brickId);

        this.favoritesSource.next(newFavorites);
    }

    public toggle(poi: IPoi): void {
        if (this.favoritesSource.value.has(poi.brickId)) {
            this.remove(poi.brickId);

            return;
        }

        this.add(poi);
    }

    public clear(): void {
        this.favoritesSource.value.clear();
    }
}
