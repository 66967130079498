import { Injectable } from "@angular/core";

import { IPoiGetDto, PoiType as ApiPoiType } from "@dtos";
import { EMPTY_COUNTRY } from "@models/country/country";
import { CountryService } from "@models/country/country.service";
import { IPoi, PoiType } from "@models/poi/poi";

@Injectable()
export class PoiMapper {
    public constructor(private readonly countryService: CountryService) {}

    public mapGetPoi = (poiDto: IPoiGetDto): IPoi => {
        const { brickType, countryId, title, ...poi } = poiDto;

        let country = this.countryService.getCountry(countryId);
        if (country == null) {
            console.error(`PoiMapper - country ${countryId} not found for poi ${poi.brickId}`);
            country = EMPTY_COUNTRY;
        }

        return {
            brickId: poi.brickId,
            country,
            countryId,
            description: poi.description,
            lat: poi.lat,
            lng: poi.lng,
            placesIds: poi.places.map((place) => place.placeId),
            poiMedia: poi.poiMedia,
            popularity: poi.popularity,
            title: title.trim(),
            type: getPoiType(brickType),
        };
    };
}

function getPoiType(type: string): PoiType {
    switch (type) {
        case ApiPoiType.ACCOMMODATION: {
            return PoiType.ACCOMMODATION;
        }
        case ApiPoiType.EXPLORATION: {
            return PoiType.EXPLORATION;
        }
        default:
        case ApiPoiType.PLACE: {
            return PoiType.PLACE;
        }
    }
}
