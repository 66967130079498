import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { PoiRepository } from "@api-binding/index";
import { ModalManagerService } from "@core/modal/modal.manager.service";
import { ICountry } from "@models/country/country";
import { IActivity, IPlace, IPoi, isActivity, isPlace, PoiType } from "./poi";

@Injectable({
    providedIn: "root",
})
export class PoiService {
    // pois$ contains all POIs that were downloaded during the session
    // data structure : countryId -> IPoi[]
    public pois$: Observable<Map<number, IPoi[]>>;
    // flattened version of pois$
    public allPois$: Observable<IPoi[]>;

    public renderedPois$: Observable<IPoi[]>;
    // poi in focus
    public focus$: Observable<IPoi | null>;

    private readonly poisSource = new BehaviorSubject<Map<number, IPoi[]>>(new Map<number, IPoi[]>());
    private readonly renderedPoisSource = new BehaviorSubject<IPoi[]>([]);
    private readonly focusSource = new BehaviorSubject<IPoi | null>(null);

    public constructor(
        private readonly poiRepository: PoiRepository,
        private readonly modalManagerService: ModalManagerService,
    ) {
        this.pois$ = this.poisSource.asObservable();
        this.renderedPois$ = this.renderedPoisSource.asObservable();
        this.focus$ = this.focusSource.asObservable();

        // get a flattened list of pois$ Map
        this.allPois$ = this.pois$.pipe(
            map((poisMap) => Array.from(poisMap.values()).flat()),
            shareReplay(1),
        );
    }

    public addPois(countryId: number, pois: IPoi[]): void {
        const countryPois = this.poisSource.value.get(countryId) ?? [];

        this.setPois(countryId, [...countryPois, ...pois]);
    }

    public setPois(countryId: number, pois: IPoi[]): void {
        this.poisSource.value.set(countryId, [...pois]);

        this.poisSource.next(new Map(this.poisSource.value));
    }

    public setRenderedPois(pois: IPoi[]): void {
        pois.sort(sortByTitle);
        this.renderedPoisSource.next(pois);
    }

    public setSelectedPoi(poi: IPoi): void {
        if (
            this.focusSource.value == null ||
            (null != this.focusSource.value && this.focusSource.value.brickId !== poi.brickId)
        ) {
            this.focusSource.next(poi);
        } else {
            this.modalManagerService.openCard(poi);
            this.focusSource.next(null);
        }
    }

    public countryHasPoi(country: ICountry | undefined): boolean {
        return country != null ? this.poisSource.value.has(country.id) : false;
    }

    public async loadPois(country: ICountry): Promise<void> {
        if (this.poisSource.value.get(country.id) == null) {
            const countryPois = await this.poiRepository.getPoisByCountry(country.id).toPromise();
            if (countryPois.length > 0) {
                this.addPois(country.id, countryPois);
            }
        }
    }

    public getActivitiesByPlace(place: IPlace): IActivity[] {
        const pois = this.poisSource.value.get(place.countryId);

        let activities: IActivity[] = [];

        if (pois != null) {
            activities = pois
                .filter((poi): poi is IActivity => isActivity(poi) && poi.type === PoiType.EXPLORATION)
                .filter((activity) => activity.placesIds.includes(place.brickId));
        }

        return activities;
    }

    public getPlacesOfActivity(activity: IPoi): IPlace[] {
        const places: IPlace[] = [];

        const pois = this.poisSource.value.get(activity.countryId);

        if (pois != null) {
            const poisPlaces = pois.filter((poi): poi is IPlace => isPlace(poi) && poi.type === PoiType.PLACE);

            activity.placesIds.forEach((placeId) => {
                const place = poisPlaces.find((poi) => poi.brickId === placeId);

                if (null != place) {
                    places.push(place);
                }
            });
        }

        return places;
    }

    public getByTitle(search: string): Observable<IPoi[]> {
        return this.poiRepository.getByTitle(search);
    }

    public clearFocus() {
        this.focusSource.next(null);
    }

    public setFocus(poi: IPoi) {
        this.focusSource.next(poi);
    }
}

function sortByTitle(poi1: IPoi, poi2: IPoi): number {
    return poi1.title.localeCompare(poi2.title, "fr");
}
