import { Component } from "@angular/core";
import { KeyboardService } from "../keyboard.service";

import { DeviceService } from "@models";

@Component({
    selector: "mv-keyboard",
    styleUrls: ["./keyboard.component.scss"],
    templateUrl: "./keyboard.component.html",
})
export class KeyboardComponent {
    public initialPosition = { x: 0, y: 0 };

    public keyboardWidth = 0;

    constructor(
        private readonly deviceService: DeviceService,
        public readonly keyboardService: KeyboardService,
    ) {
        // take the same ruls as scss keyboard is device divide by 3
        this.keyboardWidth = deviceService.getDeviceWidth() / 3;
        // center the keyboard
        this.initialPosition.x = -(this.keyboardWidth / 3);
    }
}
