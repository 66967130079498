import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { PlayerService } from "../../../app/core/player/player.service";

import { ITag } from "../tag/tag";
import { IVideo } from "./video";

export const HOMEVIDEOTAGS = {
    cover: "CoverBrush",
};

@Injectable({
    providedIn: "root",
})
export class VideoService {
    // all videos
    public videos: IVideo[];
    public videosMap: Map<number, IVideo>;

    // cover video
    public coverVideos$: Observable<IVideo[]>;

    // videos observable
    public videos$: Observable<IVideo[]>;

    public filteredVideos$: Observable<IVideo[]>;

    public filters$: Observable<Set<number>>;
    private readonly videosSource = new BehaviorSubject<IVideo[]>([]);
    private readonly filteredVideosSource = new BehaviorSubject<IVideo[]>([]);
    private readonly filtersSource = new BehaviorSubject<Set<number>>(new Set());

    public constructor(private readonly playerService: PlayerService) {
        this.videos$ = this.videosSource.asObservable();
        this.videos$.subscribe((videos) => {
            this.videos = videos;
            this.videosMap = getVideosMap(videos);
        });

        this.filteredVideos$ = this.filteredVideosSource.asObservable();

        this.filters$ = this.filtersSource.asObservable();
        this.filters$.subscribe((activeFilters) => {
            if (activeFilters.size === 0) {
                this.setFilteredVideos(this.videos);
            } else {
                // clear filtered videos
                this.setFilteredVideos([]);

                // foreach active filter get matching videos
                activeFilters.forEach((tagId) => {
                    const filteredVideos = this.videos.filter((video: IVideo) => {
                        return (
                            video.tags.filter((tag: ITag) => {
                                return tag.id === tagId;
                            }).length > 0
                        );
                    });

                    this.addFilteredVideos(filteredVideos);
                });
            }
        });

        this.coverVideos$ = this.videos$.pipe(
            map((videos: IVideo[]) =>
                videos.filter((video: IVideo) => {
                    return (
                        video.tags.filter((tag: ITag) => {
                            return tag.title === HOMEVIDEOTAGS.cover;
                        }).length > 0
                    );
                }),
            ),
        );
    }

    public addFilteredVideos(videos: IVideo[]): void {
        this.filteredVideosSource.next([...this.filteredVideosSource.value, ...videos]);
    }

    public setFilteredVideos(videos: IVideo[]): void {
        this.filteredVideosSource.next([...videos]);
    }

    public setVideos(videos: IVideo[]): void {
        this.videosSource.next([...videos]);
    }

    public getVideo(videoId: number): IVideo | undefined {
        return this.videosMap.get(videoId);
    }

    public getVisibleTagsOfVideo(video: IVideo): ITag[] {
        return video.tags.filter((tag) => tag.visibility);
    }

    public getRelatedVideos(video: IVideo): IVideo[] {
        let relatedVideos: IVideo[] = [];
        relatedVideos.push(video);

        const tags = this.getVisibleTagsOfVideo(video);
        tags.forEach((tag) => {
            const rVideos = this.videos.filter((v: IVideo) => {
                return (
                    v.tags.filter((t: ITag) => {
                        return t.id === tag.id;
                    }).length > 0
                );
            });

            relatedVideos = relatedVideos.concat(rVideos.filter((videos) => videos.id !== video.id));
        });

        return relatedVideos;
    }

    public toggleTagFilter(filter: number) {
        const newFilters = new Set(this.filtersSource.value);

        if (newFilters.has(filter)) {
            newFilters.delete(filter);
        } else {
            newFilters.add(filter);
        }

        this.filtersSource.next(newFilters);
    }

    public playVideo(video: IVideo) {
        const relatedVideos = this.getRelatedVideos(video);
        this.playerService.show(video, relatedVideos);
    }

    public clear() {
        this.videos.forEach((video: IVideo) => {
            if (video.watched != null) {
                video.watched = false;
            }
        });

        this.setFilteredVideos(this.videos);
    }
}

function getVideosMap(videos: IVideo[]): Map<number, IVideo> {
    const videosMap = new Map(videos.map<[number, IVideo]>((video) => [video.id, video]));

    return videosMap;
}
