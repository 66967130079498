import { Injectable } from "@angular/core";

import { environment } from "@environment";

import { MediaContext } from "./media";

@Injectable({ providedIn: "root" })
export class MediaService {
    public getMediaUrl(url: string | null, context: MediaContext): string {
        if (null != url) {
            // #25897 - Default poi image
            return `${environment.moustacheAssetsOptimizedPath}${url}`;
        }

        return `${environment.baseUrl}/assets/img/poi_default_${context}.png`;
    }
}
