export interface ICountry {
    id: number;
    name: string;
    code: string;
    coverUrl: string;
    popularity: number;
}

export const EMPTY_COUNTRY = {
    code: "",
    coverUrl: "",
    id: 99999,
    name: "",
    popularity: 0,
};
