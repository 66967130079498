import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DragDropModule } from "@angular/cdk/drag-drop";

import { KeyboardComponent } from "./keyboard/keyboard.component";

@NgModule({
    declarations: [KeyboardComponent],
    exports: [KeyboardComponent],
    imports: [CommonModule, DragDropModule],
})
export class KeyboardModule {}
