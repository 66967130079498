import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ISessionForSave } from "@dtos";
import { environment } from "@environment";
import { ISession } from "@models";
import { SessionMapper } from "./session.mapper";

@Injectable()
export class SessionRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly sessionMapper: SessionMapper,
    ) {}

    public async create(session: ISession): Promise<ISession | null> {
        const sessionToSave = this.sessionMapper.mapToPostSession(session);

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http
            .post<ISessionForSave>(`${environment.moustacheApiUrl}/sessions`, sessionToSave, httpOptions)
            .pipe(
                map(this.sessionMapper.mapPostResult),
                catchError((error) => {
                    console.error(`SessionRepository - error when creating session`, error);

                    return of(null);
                }),
            )
            .toPromise();
    }

    public async end(session: ISession): Promise<ISession | null> {
        const sessionToUpdate = this.sessionMapper.mapToPatchSession(session);

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http
            .patch<ISessionForSave>(
                `${environment.moustacheApiUrl}/sessions/${session.id}`,
                sessionToUpdate,
                httpOptions,
            )
            .pipe(
                map(this.sessionMapper.mapPostResult),
                catchError((error) => {
                    console.error(`SessionRepository - error when ending session`, error);

                    return of(null);
                }),
            )
            .toPromise();
    }
}
