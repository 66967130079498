import { Injectable } from "@angular/core";
import interact from "interactjs";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { take } from "rxjs/operators";

import { initialDevice } from "@models/device/device";
import { DeviceService } from "@models/device/device.service";

// zoom level to display the whole planet, depends on the format of the screen
const GLOBAL_ZOOM = 1.5;
// const EIFFEL_TOWER_GPS: [number, number] = [48.8583, 2.2945];
const CAIRO_GPS: [number, number] = [30.06263, 31.24967];
const INITIAL_ZOOM = 1.7;

// on tactile device, the hold event is very difficult to achieve :
// InteractJS interprets tiny movements as a drag and drop, increase the move tolerance to fix that
const POINTER_MOVE_TOLERANCE = 5;

import { environment, EnvironmentType } from "@environment";
import { Theme } from "@models/theme/theme";
import { GpsCoordinates } from "mv-quotations-models";
import { IMapOptions } from "./map-lib/models";

@Injectable()
export class ConfigService {
    public mapOptions: IMapOptions = {
        center: new GpsCoordinates(...CAIRO_GPS),
        mapServerUrl: environment.mapServerUrl,
        minZoom: GLOBAL_ZOOM,
        ratioH: initialDevice.ratioH,
        ratioW: initialDevice.ratioW,
        zoom: INITIAL_ZOOM,
    };
    public swiperOptions: { [key: string]: SwiperConfigInterface } = {
        card: {
            ...DEFAULT_SWIPER_CONFIG,
            loop: true,
            slidesPerView: "auto",
            swipeHandler: ".card__actions",
        },
        fullscreen: {
            ...DEFAULT_SWIPER_CONFIG,
            loop: true,
            slidesPerView: 1,
        },
        login: {
            ...DEFAULT_SWIPER_CONFIG,
            centerInsufficientSlides: true,
            loop: false,
            slidesPerView: "auto",
        },
        poiMedia: { ...DEFAULT_SWIPER_CONFIG, loop: true, slidesPerView: "auto" },
        shortcut: {
            ...DEFAULT_SWIPER_CONFIG,
            centeredSlides: true,
            freeMode: true,
            freeModeMomentum: false,
            freeModeMomentumBounce: false,
            freeModeSticky: true,
            loop: true,
            loopAdditionalSlides: 20,
            loopedSlides: 10,
            slidesPerView: "auto",
            speed: 3000,
        },
    };
    public shopId = 1;
    public theme = Theme.MARCOVASCO;
    public screensaverTimeLoginMinute = 2;
    public screensaverTimeTripMinute = environment.screensaverTimeTripMinute;

    public slackUrl = "https://hooks.slack.com/services/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";

    public frenchKeyboard = {
        buttonTheme: [
            {
                buttons: "{enter}",
                class: "keyboard--highlight",
            },
        ],
        display: {
            "{bksp}": "Retour",
            "{clear}": "Vider",
            "{enter}": "Entrée",
            "{hide}": "Fermer",
            "{lock}": "Ver Maj",
            "{shift}": "Maj",
            "{space}": "Espace",
            "{tab}": "Tab",
        },
        layout: {
            default: [
                "{hide} \u00E9 \" ' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp}",
                "{clear} a z e r t y u i o p ^ $",
                "q s d f g h j k l m \u00F9 * {enter}",
                "{lock} < w x c v b n . , ; : !",
                ".com .fr @ {space}",
            ],
            shift: [
                "{hide} 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp}",
                "{clear} A Z E R T Y U I O P \u00A8 \u00A3",
                "Q S D F G H J K L M % \u00B5 {enter}",
                "{lock} > W X C V B N ? / \u00A7",
                ".com .fr @ {space}",
            ],
        },
    };

    public constructor(private readonly deviceService: DeviceService) {
        switch (environment.type) {
            case EnvironmentType.PROD: {
                break;
            }
            case EnvironmentType.PREPROD:
            case EnvironmentType.TESTING: {
                break;
            }
            default:
            case EnvironmentType.DEV: {
                break;
            }
        }

        interact.pointerMoveTolerance(POINTER_MOVE_TOLERANCE);

        this.deviceService.device$.pipe(take(2)).subscribe((device) => {
            this.mapOptions.ratioH = device.ratioH;
            this.mapOptions.ratioW = device.ratioW;
        });
    }
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    a11y: true,
    direction: "horizontal",
};
