import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "ellipsis",
    pure: true,
})
export class EllipsisPipe implements PipeTransform {
    public transform(value: string, limit: number = 45, ellipsis: string = " ..."): string {
        if (null == value) {
            return "";
        }

        return value.length >= limit ? value.slice(0, limit).concat(ellipsis) : value;
    }
}
