import { EMPTY_COUNTRY, ICountry } from "@models/country/country";

export enum PoiType {
    ACCOMMODATION = "accommodation",
    EXPLORATION = "exploration",
    PLACE = "place",
}

export interface IPoiMedium {
    id: number;
    url: string;
}

interface IPoiBase {
    brickId: number;
    countryId: number;
    country: ICountry;
    description: string;
    lat: number;
    lng: number;
    placesIds: number[];
    placesNames?: string;
    poiMedia: IPoiMedium[];
    popularity: number;
    title: string;
}

export interface IPoiRepertory {
    key: string;
    pois: IPoi[];
}

export interface IActivity extends IPoiBase {
    type: PoiType.ACCOMMODATION | PoiType.EXPLORATION;
}

export interface IAccommodation extends IPoiBase {
    places?: IPlace[] | null;
    type: PoiType.ACCOMMODATION;
}

export interface IExploration extends IPoiBase {
    places?: IPlace[] | null;
    type: PoiType.EXPLORATION;
}

export interface IPlace extends IPoiBase {
    type: PoiType.PLACE;
}

export type IPoi = IExploration | IAccommodation | IPlace;

export function isPoi(poiOrCountry: IPoi | ICountry): poiOrCountry is IPoi {
    return (poiOrCountry as IPoi).brickId !== undefined;
}

export function isCountry(poiOrCountry: IPoi | ICountry): poiOrCountry is ICountry {
    return (poiOrCountry as ICountry).code !== undefined;
}

export function isPlace(poi: IPoi): poi is IPlace {
    return poi.type === PoiType.PLACE;
}

export function isActivity(poi: IPoi): poi is IActivity {
    return isExploration(poi) || isAccommodation(poi);
}

export function isExploration(poi: IPoi): poi is IExploration {
    return poi.type === PoiType.EXPLORATION;
}

export function isAccommodation(poi: IPoi): poi is IAccommodation {
    return poi.type === PoiType.ACCOMMODATION;
}

// filters duplicate pois
export function uniquePois<T extends IPoi | IAccommodation | IExploration | IPlace>(pois: T[]): T[] {
    return Array.from(new Map(pois.map((poi) => [poi.brickId, poi])).values());
}

export function isSamePoi(poi1: IPoi, poi2: IPoi): boolean {
    return poi1.brickId === poi2.brickId;
}

export const EMPTY_POI = {
    brickId: 0,
    country: EMPTY_COUNTRY,
    countryId: 0,
    description: "",
    lat: 0,
    lng: 0,
    placesIds: [],
    poiMedia: [],
    popularity: 0,
    title: "",
    type: PoiType.PLACE,
};
