import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ICountryGetDto } from "@dtos";
import { environment } from "@environment";
import { ICountry } from "@models";
import { MockModeService } from "../mock-mode.service";
import { CountryMapper } from "./country.mapper";

@Injectable()
export class CountryRepository {
    private isMockModeEnabled: boolean;

    public constructor(
        private readonly http: HttpClient,
        private readonly countryMapper: CountryMapper,
        private readonly mockMode: MockModeService,
    ) {
        this.mockMode.isMockModeEnabled$.subscribe((isMockModeEnabled) => (this.isMockModeEnabled = isMockModeEnabled));
    }

    public getCountries(): Observable<ICountry[]> {
        const dataSource = this.isMockModeEnabled
            ? this.getMockCountries()
            : this.http.get<ICountryGetDto[]>(`${environment.moustacheApiUrl}/countries`);

        return dataSource.pipe(
            map((countries) => countries.map(this.countryMapper.mapGetCountry)),
            catchError(() => of([])),
        );
    }

    public getMockCountries(): Observable<ICountryGetDto[]> {
        return this.http.get<ICountryGetDto[]>("assets/mocks/countries.json");
    }
}
