import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";

import { RequestCache } from "../request/cache.service";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(private readonly cache: RequestCache) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!isCachable(req)) {
            return next.handle(req);
        }

        const cachedResponse = this.cache.get(req);

        return cachedResponse ? of(cachedResponse) : sendRequest(req, next, this.cache);
    }
}

const isCachable = (req: HttpRequest<any>): boolean => req.method === "GET";

const sendRequest = (req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> => {
    return next.handle(req).pipe(
        tap((event) => {
            if (event instanceof HttpResponse) {
                cache.put(req, event);
            }
        }),
    );
};
