import { Injectable } from "@angular/core";
import { IInteractionEvent } from "./interaction.interface";

@Injectable()
export class InteractionService {
    public getEvent<T>(element: HTMLElement, event: Event, model: T): IInteractionEvent<T> {
        return {
            element,
            event,
            model,
        };
    }
}
