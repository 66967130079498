import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { ApiStrapiService } from "./index";

@NgModule({
    imports: [HttpClientModule],
    providers: [ApiStrapiService],
})
export class ApiStrapiModule {}
