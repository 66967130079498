import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IPoiGetDto } from "@dtos";
import { environment } from "@environment";
import { IPoi } from "@models";
import { MockModeService } from "../mock-mode.service";
import { PoiMapper } from "./poi.mapper";

@Injectable()
export class PoiRepository {
    private isMockModeEnabled: boolean;

    public constructor(
        private readonly http: HttpClient,
        private readonly poiMapper: PoiMapper,
        private readonly mockMode: MockModeService,
    ) {
        this.mockMode.isMockModeEnabled$.subscribe((isMockModeEnabled) => (this.isMockModeEnabled = isMockModeEnabled));
    }

    public getPoisByCountry(countryId: number): Observable<IPoi[]> {
        return this.query(`/pois/country/${countryId}`);
    }

    public getByTitle(search: string): Observable<IPoi[]> {
        return this.query(`/pois?title=${search}`);
    }

    public getStack(poi: IPoi): Observable<IPoi[]> {
        return this.query(`/pois/${poi.brickId}/stack`);
    }

    public getMockPois(): Observable<IPoiGetDto[]> {
        return this.http.get<IPoiGetDto[]>("assets/mocks/pois.json");
    }

    private query(query: string): Observable<IPoi[]> {
        const dataSource = this.isMockModeEnabled
            ? this.getMockPois()
            : this.http.get<IPoiGetDto[]>(`${environment.moustacheApiUrl}${query}`);

        return dataSource.pipe(
            map((pois) => pois.map(this.poiMapper.mapGetPoi)),
            catchError(() => of([])),
        );
    }
}
