<div
    cdkDrag
    cdkDragBoundary="mv-dali"
    [cdkDragFreeDragPosition]="initialPosition"
    class="keyboard"
    [ngClass]="keyboardService.display ? 'keyboard--show' : 'keyboard--hide'"
>
    <div class="simple-keyboard"></div>
    <div cdkDragHandle class="keyboard--drag">⌨</div>
</div>
