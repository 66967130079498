import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";

import { ICountry } from "@models/country/country";
import { CountryService } from "@models/country/country.service";
import { IPlace } from "@models/poi/poi";
import { PoiService } from "@models/poi/poi.service";
import { UserService } from "@models/user/user.service";

export type Itinerary = Map<ICountry, IPlace[]>;

@Injectable({
    providedIn: "root",
})
export class ItineraryService {
    public itineraries$: Observable<Itinerary>;
    private readonly itinerariesSource = new BehaviorSubject<Itinerary>(new Map<ICountry, IPlace[]>());

    public constructor(
        private readonly countryService: CountryService,
        private readonly poiService: PoiService,
        private readonly userService: UserService,
    ) {
        this.itineraries$ = this.itinerariesSource.pipe(
            tap((itineraries) => {
                // when a new itinerary is added in itinerary
                itineraries.forEach(async (places, country) => {
                    await Promise.all([
                        // fetch the POIs if needed
                        this.poiService
                            .loadPois(country)
                            // load the navigation history after pois are loaded, in order to map the navigations
                            .then(() => this.userService.loadNavigationHistory(country)),
                        // load the user search history
                        this.userService.loadSearchHistory(country),
                    ]);
                });
            }),
            shareReplay(1),
        );
    }

    public addCountry(country: ICountry): void {
        const newItineraries = new Map(this.itinerariesSource.value);
        newItineraries.set(country, []);
        this.itinerariesSource.next(newItineraries);
    }

    public removeCountry(country: ICountry): void {
        const newItineraries = new Map(this.itinerariesSource.value);
        newItineraries.delete(country);
        this.itinerariesSource.next(newItineraries);
    }

    public addPlace(place: IPlace): void {
        const newItineraries = new Map(this.itinerariesSource.value);
        const country = this.countryService.getCountry(place.countryId);

        if (country == null) {
            console.error(`ItineraryService - addPlace : country ${place.countryId} not found`);

            return;
        }

        if (!newItineraries.has(country)) {
            newItineraries.set(country, [place]);
            this.itinerariesSource.next(newItineraries);

            return;
        }

        const places = newItineraries.get(country);

        if (places != null) {
            // inject only if not exist
            const placeExist = places.filter((p: IPlace) => p.brickId === place.brickId);
            if (placeExist.length === 0) {
                newItineraries.set(country, [...places, place]);
                this.itinerariesSource.next(newItineraries);
            }
        }
    }

    public removePlace(placeToRemove: IPlace): void {
        const country = this.countryService.getCountry(placeToRemove.countryId);

        if (country == null) {
            console.error(`ItineraryService - addPlace : country ${placeToRemove.countryId} not found`);

            return;
        }

        const newItineraries = new Map(this.itinerariesSource.value);
        if (!newItineraries.has(country)) {
            // country not found in itinerary : nothing to remove
            return;
        }

        const places = newItineraries.get(country);

        if (places != null) {
            const placeIndex = places.findIndex((place) => place.brickId === placeToRemove.brickId);
            if (placeIndex === -1) {
                // place not found : nothing to remove
                return;
            }

            places.splice(placeIndex, 1);
            this.itinerariesSource.next(newItineraries);
        }
    }

    public hasAtLeastOneItinerary(): boolean {
        return this.itinerariesSource.value.size > 0;
    }

    public hasCountry(country: ICountry): boolean {
        return this.itinerariesSource.value.has(country);
    }

    public clear(): void {
        this.itinerariesSource.next(new Map<ICountry, IPlace[]>());
    }
}
