import { Injectable } from "@angular/core";

import { ICountryGetDto } from "@dtos";
import { ICountry } from "@models";

@Injectable()
export class CountryMapper {
    public mapGetCountry = (countryDto: ICountryGetDto): ICountry => {
        return { ...countryDto };
    };
}
