import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "mapKeys",
    pure: true,
})
export class MapKeysPipe<T, U> implements PipeTransform {
    public transform(map: Map<T, U>): T[] {
        return Array.from(map.keys());
    }
}
