import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class MockModeService {
    public isMockModeEnabled$: Observable<boolean>;
    private readonly isMockModeEnabledSource = new BehaviorSubject<boolean>(false);

    public constructor() {
        this.isMockModeEnabled$ = this.isMockModeEnabledSource.asObservable();
    }

    public toggleMockMode(): void {
        this.isMockModeEnabledSource.next(!this.isMockModeEnabledSource.value);
    }
}
