import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class InsurancesService {
    constructor(private readonly http: HttpClient) {}

    /**
     * Get all formulas
     */
    public getAllFormulas(params: any) {
        let resource = `allprices?amount=${params.amount}&pax=${params.pax}&descr=1`;

        if (params.margin != null) {
            resource += "&margin=" + params.margin;
        }

        if (params.distance != null) {
            resource += "&distance=" + params.distance;
        }

        if (params.category != null) {
            resource += "&category=" + params.category;
        }

        if (params.date != null) {
            resource += "&date=" + params.date;
        }

        if (params.duration != null) {
            resource += "&duration=" + params.duration;
        }

        const url = `${environment.insurancesApiEndPoint}${resource}`;

        const headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${environment.insurancesApiToken}`,
        });

        let promise = new Promise<any>((resolve, reject) => {
            this.http
                .get(`${url}`, { headers })
                .toPromise()
                .then((response: any) => {
                    // response is an object with a lot of properties, transformr it to an array
                    const data = [];

                    for (const item in response) {
                        if (item !== "insurer" && 1 === response[item]["isAvailable"]) {
                            data.push({
                                code: item,
                                contractMV: response[item]["contractMV"],
                                contractPV: response[item]["contractPV"],
                                description: response[item]["description"],
                                isApplicable: response[item]["purchasePricePerPax"] === "NA" ? false : true,
                                link: null,
                                quantity: response[item]["pax"],
                                salePrice: response[item]["salePrice"],
                                title: response[item]["name"],
                                totalPrice: response[item]["purchasePrice"],
                                unitPrice: response[item]["purchasePricePerPax"],
                                unitPriceSell: response[item]["salePricePerPax"],
                            });
                        }
                    }

                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        return promise;
    }
}
