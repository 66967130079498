import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { INavigationHistoryGetDto, ISearchHistoryGetDto, IShopGetDto } from "@dtos";
import { environment } from "@environment";
import { IPoi, IUser } from "@models";
import { UserMapper } from "./user.mapper";

@Injectable()
export class UserRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly userMapper: UserMapper,
    ) {}

    public getUsers(shopId: number): Observable<IUser[]> {
        return this.http.get<IShopGetDto>(`${environment.moustacheApiUrl}/shops/${shopId}/users`).pipe(
            map(this.userMapper.mapGetShop),
            map((users) => users.map(this.userMapper.mapGetUser)),
            catchError(() => of([])),
        );
    }

    public getNavigationHistory(userId: number, countryId: number): Observable<IPoi[]> {
        const params = new HttpParams().set("countryId", `${countryId}`);
        const url = `${environment.moustacheApiUrl}/users/${userId}/navigation`;

        return this.http.get<INavigationHistoryGetDto[]>(url, { params }).pipe(
            map((navigationHistories) => this.userMapper.mapGetNavigationHistory(navigationHistories, countryId)),
            catchError(() => of([])),
        );
    }

    public getSearchHistory(userId: number, countryId: number): Observable<string[]> {
        const params = new HttpParams().set("countryId", `${countryId}`);
        const url = `${environment.moustacheApiUrl}/users/${userId}/search`;

        return this.http.get<ISearchHistoryGetDto[]>(url, { params }).pipe(
            map(this.userMapper.mapGetSearchHistory),
            catchError(() => of([])),
        );
    }
}
