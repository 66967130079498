import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import { ConfigService } from "@app/config.service";
import { CoreModule } from "@core/core.module";

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [AppRoutingModule, BrowserAnimationsModule, BrowserModule, CoreModule, ReactiveFormsModule, FormsModule],
    providers: [ConfigService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
