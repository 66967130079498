import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: "[scrollSpy]",
})
export class ScrollSpyDirective {
    @Output() public readonly scrollPercent = new EventEmitter<number>();

    constructor(private readonly el: ElementRef<HTMLElement>) {}

    @HostListener("scroll", [])
    public onScroll(): void {
        const scrollTop = this.el.nativeElement.scrollTop;
        const scrollHeight = this.el.nativeElement.scrollHeight;
        const scrollPercent = scrollHeight !== 0 ? scrollTop / scrollHeight : 0;
        this.scrollPercent.emit(scrollPercent);
    }
}
