// environment.preprod.ts
import { EnvironmentType, IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
    avatarAssetsPath: "avatars",
    apiEndPoint: "https://preprod.api.client.marcovasco.fr/1/",
    apiLogin: "quotations-view",
    apiToken: "v|iYc>37fDm.}@0@",
    baseUrl: "https://preprod.dali.moustache.marcovasco.fr",
    clientBaseUrl: "https://preprod.client.marcovasco.fr",
    countryAssetsPath: "covers",
    credentials: { username: "user", password: "password" },
    insurancesApiEndPoint: "https://preprod.assurances.marcovasco.fr/1/",
    insurancesApiToken:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE0OTM5NTk4MzQsImp0aSI6IjV6K01ZN3B5a0FVRWFIVHFYbjJGVldjM0VPS1BoTnFoUHBKeEpGVDE5SFk9IiwiaXNzIjoiTWFyY29WYXNjbyIsImRhdGEiOnsiYXBwIjoiQmVoYXQifX0.KgJAxmRmzE9aajsZVkbYyb4UB5PR7gnuE4scdfuVHc2GJIOoKKDhp-16xQLU3MsapyG9eUQQKXxpfOhZdkj5dQ",
    mapServerUrl: "https://maps.moustache.marcovasco.fr",
    moustacheApiUrl: "https://preprod.api.moustache.marcovasco.fr",
    moustacheAssetsBasePath: "https://preprod.api.moustache.marcovasco.fr/moustache/",
    moustacheAssetsOptimizedPath: "https://preprod.api.moustache.marcovasco.fr/webp/moustache/",
    production: false,
    screensaverTimeTripMinute: 2,
    type: EnvironmentType.PREPROD,
    quotationApiUrl: "https://preprod.client.marcovasco.fr/api/1/quotations",
    newQuotationApiUrl: "https://preprod.pvcrm.com/c/api/opportunities",
    strapiApiUrl: "https://www.develop-sr3snxi-miguz5mv6t4f6.fr-3.platformsh.site/api",
    strapiApiToken:
        "dab0ccfb2fda134d64f824ba5e3a0fb7c4ca2f839f8f25aee06a36b0d035775725f0a218f64f640953d92cfd336534388ef2dfb82ae04a00b67cfa46b96053e2e085295235e0a34d5bd49b8cfaa4d71351f4b8bf727d18d7fbaf445bc845cda592df840f9413c21f06a2519c42335c1dbc45127cf54308b3e4edab0e8eec9a85",
    strapiPublicUrl: "https://www.develop-sr3snxi-miguz5mv6t4f6.fr-3.platformsh.site",
    streamingVideoPath: "streaming",
    inatictivityTime: 3000, // Ajouté
    mediaAssetsPath: "media",
    mediaAssetsPathOptimized: "webp/videos",
    siteAssetsUrl: "http://c0.marcovasco.fr",
};
