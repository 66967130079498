import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { interval, BehaviorSubject, Subscription } from "rxjs";
import { takeWhile } from "rxjs/operators";

import { MenuService, ScreensaverService } from "@models";
import { KeyboardService } from "@core/keyboard/keyboard.service";

@Injectable({ providedIn: "root" })
export class IdleService implements OnDestroy {
    // properties to manage screensaver
    private readonly renderer: Renderer2;
    private lastInteraction: Date = new Date();
    private definedInactivityPeriod = 10000;
    private loginTime: number;
    private tripTime: number;

    // New property to manage pause state
    private onPause: boolean = false;

    // list on user interaction
    private readonly subscriptions = new Subscription();
    private sessionsLive = false;

    // handle ui-kit screensaver
    private readonly screensaver: HTMLElement | any;

    // BehaviorSubject to manage the shouldClear$ observable
    private readonly shouldClearSource = new BehaviorSubject<boolean>(false);
    public shouldClear$ = this.shouldClearSource.asObservable();

    public constructor(
        private readonly menuService: MenuService,
        private readonly rendererFactory2: RendererFactory2,
        private readonly screensaverService: ScreensaverService,
        private readonly keyboardService: KeyboardService,
    ) {
        // if screensaver from ui-kit exist
        if (document.querySelector("mv-screensaver") != null && this.screensaver == null) {
            this.screensaver = document.querySelector("mv-screensaver");
            // listen when it's hide and start a new session
            this.screensaver.addEventListener("screensaver-hide", (e: any) => {
                this.startSession();
                this.menuService.setMenuPosition(e.detail.position);
            });
        }
        this.renderer = this.rendererFactory2.createRenderer(null, null);
        // if any interaction computer or touch screen
        this.renderer.listen("document", "mousemove", () => {
            this.resetSession();
        });

        this.subscriptions
            .add(this.screensaverService.loginTime$.subscribe((loginTime) => (this.loginTime = loginTime)))
            .add(this.screensaverService.tripTime$.subscribe((tripTime) => (this.tripTime = tripTime)));
    }

    // set screensaver launch time
    public setInactivityPeriod(min: number) {
        this.definedInactivityPeriod = min * 60 * 1000;
    }

    // init from login page
    public initIdleForLogin() {
        this.setInactivityPeriod(this.loginTime);
        this.startSession();
    }

    // init from trip page
    public initIdlePeriodForTrip() {
        this.setInactivityPeriod(this.tripTime);
        this.startSession();
    }

    // start a session and subscribe to timer
    public startSession() {
        if (!this.sessionsLive) {
            this.resetSession();
            this.subscriptions.add(this.idlePoll().subscribe());
        }
    }

    // everytime there is interaction just reset timer
    public resetSession() {
        this.lastInteraction = new Date();
        this.shouldClearSource.next(false); // Example of using shouldClearSource
    }

    // timer manager
    public idlePoll() {
        return interval(1000).pipe(
            takeWhile(() => {
                // if timer reach show screensaver
                if (new Date().getTime() - this.lastInteraction.getTime() >= this.definedInactivityPeriod) {
                    this.sessionsLive = false;
                    // Close keyboard
                    this.keyboardService.hide();
                    this.showScreensaver();
                    this.shouldClearSource.next(true); // Example of using shouldClearSource
                } else {
                    this.sessionsLive = true;
                }

                return new Date().getTime() - this.lastInteraction.getTime() < this.definedInactivityPeriod;
            }),
        );
    }

    // show ui-kit screensaver
    public showScreensaver() {
        if (this.screensaver != null) {
            this.screensaver.show();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    // toggle Pause for screensaver
    public setPause(pause: boolean) {
        this.onPause = pause;
        // if pause is removed reset session just in case
        if (!this.onPause) {
            this.startSession();
        }
    }
}
