import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class EmailService {
    public emailFormIsOpened$: Observable<boolean>;
    private readonly emailFormIsOpenedSource = new BehaviorSubject<boolean>(false);

    public constructor() {
        this.emailFormIsOpened$ = this.emailFormIsOpenedSource.asObservable();
    }

    public toggleForm(): void {
        this.emailFormIsOpenedSource.next(!this.emailFormIsOpenedSource.value);
    }

    public closeForm(): void {
        this.emailFormIsOpenedSource.next(false);
    }
}
