<ng-container *ngIf="popinService.popinData$ | async as popin">
    <div class="popin" [ngClass]="popin.state ? 'popin--show' : 'popin--hide'">
        <div class="popin__background">
            <div class="popin--container">
                <div class="popin--content">
                    <div class="popin--content__title" [innerHtml]="popin.title | safeHtml"></div>
                    <div class="popin--content__action">
                        <div class="popin--content__action--no" (touchstart)="confirmPopin()">
                            <div class="popin--content__action--text">
                                {{ popin.confirmation }}
                            </div>
                        </div>
                        <div class="popin--content__action--yes" (touchstart)="cancelPopin()">
                            <div class="popin--content__action--text">
                                {{ popin.cancellation }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
