import { HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface IRequestCacheEntry {
    url: string;
    response: HttpResponse<any>;
    lastRead: number;
}

const maxAge = 100 * 60 * 24 * 30; // maximum cache age (ms)

@Injectable()
export class RequestCache {
    public cache = new Map<string, IRequestCacheEntry>();

    public get(req: HttpRequest<any>): HttpResponse<any> | undefined {
        const url = req.urlWithParams;
        const cached = this.cache.get(url);

        if (!cached) {
            return undefined;
        }

        const isExpired = cached.lastRead < Date.now() - maxAge;

        return isExpired ? undefined : cached.response;
    }

    public put(req: HttpRequest<any>, response: HttpResponse<any>): void {
        const url = req.urlWithParams;

        const newEntry = { url, response, lastRead: Date.now() };
        // only set cache if body has content
        const body = response.body;
        if (
            body != null &&
            ((typeof body === "object" && !Array.isArray(body)) || (Array.isArray(body) && body.length > 0))
        ) {
            this.cache.set(url, newEntry);
        }

        // remove expired cache entries
        const expired = Date.now() - maxAge;
        this.cache.forEach((entry) => {
            if (entry.lastRead < expired) {
                this.cache.delete(entry.url);
            }
        });
    }

    public clear() {
        this.cache.clear();
    }
}
