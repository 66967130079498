import { Injectable } from "@angular/core";

import * as slackNotify from "slack-notify";

import { ConfigService } from "@app/config.service";

@Injectable({ providedIn: "root" })
export class SlackService {
    public static MOUSTACHE_CHANNEL: string = "moustache";

    private readonly slack: any;

    constructor(private readonly configService: ConfigService) {
        this.slack = slackNotify(this.configService.slackUrl);
    }

    public notify(message: string, data: any) {
        this.slack.alert(
            {
                channel: SlackService.MOUSTACHE_CHANNEL,
                text: `${message}
            ${JSON.stringify(data)}
            `,
            },
            (err: any) => {
                if (err) {
                    console.error("Slack error");
                    const tripsToSend =
                        null != localStorage.getItem("tripsToSend")
                            ? JSON.parse(localStorage.getItem("tripsToSend") as string)
                            : [];

                    tripsToSend.push(data);

                    localStorage.setItem("tripsToSend", JSON.stringify(tripsToSend));
                } else {
                    console.log(`Successfully sent to slack`);
                }
            },
        );
    }
}
