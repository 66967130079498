import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { ICountry } from "./country";

@Injectable({
    providedIn: "root",
})
export class CountryService {
    public countries$: Observable<ICountry[]>;
    public renderedCountries$: Observable<ICountry[]>;
    public countriesMap: Map<number, ICountry>;
    private readonly countriesSource = new BehaviorSubject<ICountry[]>([]);
    private readonly renderedCountriesSource = new BehaviorSubject<ICountry[]>([]);

    public constructor() {
        this.countries$ = this.countriesSource.asObservable();
        this.renderedCountries$ = this.renderedCountriesSource.asObservable();
        this.countries$.subscribe((countries) => (this.countriesMap = getCountriesMap(countries)));
    }

    public addCountries(countries: ICountry[]): void {
        this.countriesSource.next([...this.countriesSource.value, ...countries]);
    }

    public setCountries(countries: ICountry[]): void {
        this.countriesSource.next([...countries]);
    }

    public getCountries(): ICountry[] {
        return this.countriesSource.value;
    }

    public getCountry(countryId: number): ICountry | undefined {
        return this.countriesMap.get(countryId);
    }

    public getCountryByCode(countryCode: string): ICountry | undefined {
        return this.countriesSource.value.find((country) => country.code === countryCode);
    }

    public setRenderedCountries(countries: ICountry[]): void {
        this.renderedCountriesSource.next([...countries]);
    }
}

function getCountriesMap(countries: ICountry[]): Map<number, ICountry> {
    return new Map(countries.map((country) => [country.id, country]));
}
