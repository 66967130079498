import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import { DEFAULT_ROUTE } from "@app/routes";
import { UserService } from "@models";

@Injectable({ providedIn: "root" })
export class CanActivateUser implements CanActivate {
    public constructor(
        private readonly userService: UserService,
        private readonly router: Router,
    ) {}

    public canActivate(): Observable<boolean | UrlTree> {
        // if no currentUser : redirect to default_route
        return this.userService.currentUser$.pipe(
            map((user) => (user != null ? true : this.router.parseUrl(DEFAULT_ROUTE))),
            take(1),
        );
    }
}
