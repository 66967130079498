import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { ICountry } from "@models/country/country";
import { IMapview } from "@models/mapview/mapview";
import { IPlace, IPoi, isActivity } from "@models/poi/poi";
import { PoiService } from "@models/poi/poi.service";

@Injectable({
    providedIn: "root",
})
export class ZoomService {
    public zoomOnCountry$: Observable<ICountry>;
    public zoomOnMapview$: Observable<IMapview>;
    public zoomOnPois$: Observable<IPoi[]>;
    private readonly zoomOnCountrySource = new Subject<ICountry>();
    private readonly zoomOnMapviewSource = new Subject<IMapview>();
    private readonly zoomOnPoisSource = new Subject<IPoi[]>();
    private poisMap: Map<number, IPoi[]>;

    public constructor(private readonly poiService: PoiService) {
        this.zoomOnCountry$ = this.zoomOnCountrySource.asObservable();
        this.zoomOnMapview$ = this.zoomOnMapviewSource.asObservable();
        this.zoomOnPois$ = this.zoomOnPoisSource.asObservable();
        this.poiService.pois$.subscribe((poisMap) => (this.poisMap = poisMap));
    }

    public zoomOnPlace(place: IPlace): void {
        const countryPois = this.poisMap.get(place.countryId);
        if (countryPois == null) {
            return;
        }

        this.zoomOnPoisSource.next(countryPois.filter((poi) => isActivity(poi) && poi.placesIds[0] === place.brickId));
    }

    public zoomOnPois(pois: IPoi[]): void {
        this.zoomOnPoisSource.next(pois);
    }

    public zoomOnCountry(country: ICountry): void {
        this.zoomOnCountrySource.next(country);
    }

    public zoomOnMapview(mapview: IMapview): void {
        this.zoomOnMapviewSource.next(mapview);
    }
}
