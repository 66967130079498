import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject, combineLatest, interval, Observable, of, Subject } from "rxjs";
import { delayWhen, map, mapTo, shareReplay } from "rxjs/operators";

import { ICountry } from "../country/country";
import { CountryService } from "../country/country.service";
import { ItineraryService } from "../itinerary/itinerary.service";
import { IPoi } from "../poi/poi";
import { PoiService } from "../poi/poi.service";
import { ZoomService } from "../zoom/zoom.service";

export type SearchResult = [ICountry[], IPoi[]];

@Injectable({
    providedIn: "root",
})
export class SearchService {
    public searchInput$: Observable<string>;
    public searchResult$: Observable<SearchResult>;
    public searchIsActive$: Observable<boolean>;
    public searchIsFocus$: Observable<boolean>;
    public searchIsLoading$: Observable<boolean>;
    public clearInput$: Observable<boolean>;
    public searchControl = new FormControl("");
    private readonly searchInputSource = new Subject<string>();
    private readonly searchResultSource = new Subject<SearchResult>();
    private readonly searchIsActiveSource = new BehaviorSubject<boolean>(false);
    private readonly searchIsFocusSource = new BehaviorSubject<boolean>(false);
    private readonly searchIsLoadingSource = new BehaviorSubject<boolean>(false);
    private readonly clearInputSource = new BehaviorSubject<boolean>(false);

    public constructor(
        private readonly itineraryService: ItineraryService,
        private readonly countryService: CountryService,
        private readonly poiService: PoiService,
        private readonly zoomService: ZoomService,
    ) {
        this.searchInput$ = this.searchInputSource.asObservable();
        this.searchInput$.subscribe((search) => {
            this.search(search).catch((error: Error) =>
                console.error(`MenuComponent - search does not resolve`, error),
            );
        });

        this.searchResult$ = this.searchResultSource.asObservable();
        this.searchIsActive$ = this.searchIsActiveSource.asObservable();
        this.searchIsFocus$ = this.searchIsFocusSource.asObservable();
        this.clearInput$ = this.clearInputSource.asObservable();
        this.searchIsLoading$ = this.searchIsLoadingSource
            .asObservable()
            // add a one second delay after search finishes to avoid loader flicker
            .pipe(delayWhen((isLoading) => (!isLoading ? interval(1000) : of(undefined))));
    }

    public async search(search: string): Promise<boolean> {
        this.searchIsLoadingSource.next(true);

        const searchResult$ = this.itineraryService.hasAtLeastOneItinerary()
            ? this.localSearch(search)
            : this.globalSearch(search);

        searchResult$.subscribe((searchResults) => {
            this.searchResultSource.next(searchResults);
            this.searchIsLoadingSource.next(false);
        });

        return searchResult$.pipe(mapTo(true)).toPromise();
    }

    public setSearch(search: string): void {
        this.searchInputSource.next(search);
    }

    public setIsSearchActive(isSearchActive: boolean): void {
        this.searchIsActiveSource.next(isSearchActive);
    }

    public clear() {
        this.searchControl.setValue("");
        this.clearInputSource.next(true);
    }

    public setFocus(focus: boolean) {
        this.searchIsFocusSource.next(focus);
    }

    private localSearch(search: string): Observable<SearchResult> {
        return combineLatest([this.countryService.countries$, this.poiService.allPois$]).pipe(
            map((searchItems) => {
                const filteredCountries: ICountry[] = searchItems[0].filter((country: ICountry) =>
                    match(search, country.name),
                );

                const filteredPois: IPoi[] = searchItems[1].filter((poi: IPoi) => match(search, poi.title));

                const searchResult = [filteredCountries, filteredPois] as SearchResult;

                this.enterSearch(searchResult);

                return searchResult;
            }),
            shareReplay(1),
        );
    }

    private globalSearch(search: string): Observable<SearchResult> {
        return this.poiService.getByTitle(search).pipe(
            map((pois) => {
                const searchResult: SearchResult = [[], pois];
                this.zoomService.zoomOnPois(searchResult[1]);

                this.enterSearch(searchResult);

                return searchResult;
            }),
            shareReplay(1),
        );
    }

    /**
     * If only one match exist on enter hit focus
     * @param searchResult SearchResult result from the search
     */
    private enterSearch(searchResult: SearchResult): void {
        if (this.searchIsFocusSource.getValue()) {
            if (searchResult[1] != null) {
                if (searchResult[1].length === 1) {
                    const singlePoi = searchResult[1][0];
                    this.zoomService.zoomOnPois([singlePoi]);
                    this.poiService.setSelectedPoi(singlePoi);
                    this.poiService.setSelectedPoi(singlePoi);
                }
            }
        }
    }
}

function match(searchFor: string, searchIn: string): boolean {
    return normalizeString(searchIn).match(new RegExp(normalizeString(searchFor), "i")) != null;
}

function normalizeString(input: string): string {
    return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
