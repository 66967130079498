import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { SlackService } from "@core/slack/slack.service";
import { ITripForSave } from "@dtos";
import { environment } from "@environment";
import { ITrip } from "@models/trip/trip";
import { TripMapper } from "./trip.mapper";

@Injectable()
export class TripRepository {
    public constructor(
        private readonly http: HttpClient,
        private readonly tripMapper: TripMapper,
        private readonly slackService: SlackService,
    ) {}

    public save(trip: ITrip) {
        const tripToSave = this.tripMapper.mapToPostTrip(trip);

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
        };

        return this.http.post<ITripForSave>(`${environment.moustacheApiUrl}/trips`, tripToSave, httpOptions).pipe(
            catchError((err) => {
                console.error(err);

                this.slackService.notify(err, tripToSave);

                return throwError(err);
            }),
        );
    }
}
