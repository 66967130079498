import { fromEvent, of, race } from "rxjs";
import { debounceTime, delay, first, mapTo, switchMap } from "rxjs/operators";

export async function scrollPromise(element: Element, block: "start" | "center", delayTime = 30): Promise<null> {
    element.scrollIntoView({ block, behavior: "smooth" });
    if (element.parentElement == null) {
        return of(null).toPromise();
    }

    const detectScroll$ = fromEvent(element.parentElement, "scroll").pipe(first(), mapTo("scroll"));
    const scroll$ = fromEvent(element.parentElement, "scroll").pipe(debounceTime(delayTime), first(), mapTo(null));
    const timeout$ = of("timeout").pipe(delay(90));

    // detect if there is a scroll
    // if there is a scroll : wait until scroll finishes, otherwise complete observable with null
    return race(timeout$, detectScroll$)
        .pipe(switchMap((obsType) => (obsType === "scroll" ? scroll$ : of(null))))
        .toPromise();
}
