import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

const INTERVAL_KONAMI_MS = 400;
const NB_KONAMI_TAPS = 7;

import { MenuService } from "../menu/menu.service";

@Injectable({ providedIn: "root" })
export class DebugService {
    public readonly debug$: Observable<boolean>;
    private readonly debugSource = new BehaviorSubject<boolean>(false);
    private menuCounter = 0;
    private menuTimer: ReturnType<typeof setTimeout>;

    public constructor(private readonly menuService: MenuService) {
        this.debug$ = this.debugSource.asObservable();
        this.menuService.menu$
            .pipe(
                map((menu) => menu.status),
                distinctUntilChanged(),
            )
            .subscribe(() => {
                // count the number of time the menu status changes, refresh the timer on each change
                this.resetTimer();
                this.menuCounter++;

                // after a certain number of menu status change : open the debug menu
                if (this.menuCounter === NB_KONAMI_TAPS) {
                    this.open();
                }
            });
    }

    public toggle(): void {
        this.debugSource.next(!this.debugSource.value);
    }

    public open(): void {
        this.debugSource.next(true);
    }

    private resetTimer() {
        clearTimeout(this.menuTimer);
        this.menuTimer = setTimeout(() => (this.menuCounter = 0), INTERVAL_KONAMI_MS);
    }
}
