import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class ApiService {
    public constructor(private readonly http: HttpClient) {}

    public retrieveQuotation(quotationId: any): any {
        let options = {
            action: "view",
            resource: "quotations:model:default:quotations",
            resourceId: quotationId,
            method: "POST",
            url: "/quotations/async/retrieve",
            context: "dali",
        };

        return this.query(options);
    }

    protected async query(options: any): Promise<any> {
        const payload: any = {
            action: options.action,
            data: options.data,
            resource: options.resource,
            resourceId: options.resourceId,
            context: options.context,
        };

        // get token
        // let token = await this.getToken();
        // payload.token = token;

        const method = options.method;
        const url = options.url;

        const data = new FormData();
        data.append("json", JSON.stringify(payload));

        const resource = `${environment.clientBaseUrl}${url}`;

        return window
            .fetch(resource, {
                body: data,
                method,
            })
            .then((response) => response.json())
            .then((message) => message.messages)
            .catch((error) => console.log("error", error));
    }

    private async getToken() {
        const token = await this.login().catch((error) => {
            console.error(error);

            return "error";
        });

        console.log("token");
        console.log(token);

        return token;
    }

    private login(): Promise<string> {
        console.log("api service login");
        const url = `${environment.apiEndPoint}login`;

        const dataObservable = this.http.post(url, {
            username: `${environment.apiLogin}`,
            password: `${environment.apiToken}`,
        });

        return dataObservable.toPromise().then((result: { access_token: string }) => result.access_token);
    }
}
