import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { PoiRepository } from "@api-binding";
import { MapviewService } from "@models/mapview/mapview.service";
import { IPoi, isSamePoi } from "@models/poi/poi";
import { IStack } from "@models/stack/stack";
import { ZoomService } from "@models/zoom/zoom.service";

@Injectable({
    providedIn: "root",
})
export class StackService {
    public loading$: Observable<Set<number>>;
    // key of the Map: brickId of the original poi
    public stacks$: Observable<Map<number, IStack>>;
    public zoomMode$: Observable<boolean>;

    public originalPoi: IPoi | null;

    private readonly loadingSource = new BehaviorSubject<Set<number>>(new Set());
    private readonly stacksSource = new BehaviorSubject<Map<number, IStack>>(new Map<number, IStack>());
    private readonly zoomModeSource = new BehaviorSubject<boolean>(false);
    private isZoomModeEnabled: boolean;

    public constructor(
        private readonly poiRepository: PoiRepository,
        private readonly zoomService: ZoomService,
        private readonly mapviewService: MapviewService,
    ) {
        this.loading$ = this.loadingSource.asObservable();
        this.stacks$ = this.stacksSource.asObservable();
        this.zoomMode$ = this.zoomModeSource.asObservable();
        this.zoomMode$.subscribe((zoomMode) => (this.isZoomModeEnabled = zoomMode));
    }

    public async toggle(poi: IPoi) {
        if (this.stacksSource.value.has(poi.brickId)) {
            this.close(poi);
        } else {
            await this.open(poi);
        }
    }

    public changeDisplayedPoi(originalPoi: IPoi, displayedPoi: IPoi): void {
        const stack = this.stacksSource.value.get(originalPoi.brickId);
        if (stack != null && !isSamePoi(stack.displayedPoi, displayedPoi)) {
            const newStacks = new Map(this.stacksSource.value);
            newStacks.set(originalPoi.brickId, {
                ...stack,
                displayedPoi,
            });
            this.stacksSource.next(newStacks);
        }
    }

    public async getStack(poi: IPoi): Promise<IPoi[]> {
        return this.poiRepository.getStack(poi).toPromise();
    }

    public toggleLoading(poi: IPoi): void {
        const loadings = new Set(this.loadingSource.value);
        if (loadings.has(poi.brickId)) {
            loadings.delete(poi.brickId);
        } else {
            loadings.add(poi.brickId);
        }
        this.loadingSource.next(loadings);
    }

    public toggleZoomMode(): void {
        this.zoomModeSource.next(!this.zoomModeSource.value);
    }

    public close(poi: IPoi) {
        const stack = this.stacksSource.value.get(poi.brickId);
        if (stack == null) {
            // nothing to close
            return;
        }
        this.originalPoi = null;
        const newStacks = new Map(this.stacksSource.value);
        newStacks.delete(poi.brickId);
        if (this.isZoomModeEnabled) {
            // zoom back on the mapview before the stack
            this.zoomService.zoomOnMapview(stack.mapview);
        }
        this.stacksSource.next(newStacks);
    }

    public async open(poi: IPoi) {
        this.originalPoi = poi;
        const newStacks = new Map(this.stacksSource.value);
        this.toggleLoading(poi);
        const stack = await this.getStack(poi);
        this.toggleLoading(poi);
        if (this.isZoomModeEnabled && stack.length > 0) {
            // zoom on the stacks
            this.zoomService.zoomOnPois(stack);
        }
        newStacks.set(poi.brickId, {
            displayedPoi: poi,
            mapview: this.mapviewService.getCurrentMapview(),
            originalPoi: poi,
            stackedPois: [poi, ...stack],
        });
        this.stacksSource.next(newStacks);
    }
}
