export enum PoiType {
    ACCOMMODATION = "accommodation",
    COUNTRY = "country",
    EXPLORATION = "exploration",
    PLACE = "place",
}

type PoiTypeKey = keyof typeof PoiType;
export function getPoiType(): PoiType[] {
    return Object.keys(PoiType).map((enumKey) => PoiType[enumKey as PoiTypeKey]);
}
