import { AfterViewInit, Component } from "@angular/core";
import { AppRoutes } from "@app/routes";
import { take } from "rxjs/operators";

import { DeviceService, TagService, VideoService } from "@models";
import { TagRepository, VideoRepository } from "./api-binding";
import { IdleService } from "./core/idle/idle.service";

@Component({
    selector: "mv-dali",
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit {
    public AppRoutes = AppRoutes;

    public constructor(
        private readonly deviceService: DeviceService,
        private readonly tagRepository: TagRepository,
        public readonly tagService: TagService,
        private readonly videoRepository: VideoRepository,
        public readonly videoService: VideoService,
        public readonly idleService: IdleService,
    ) {
        console.log("Una matina");
        this.idleService.shouldClear$.subscribe((clearState: boolean) => {
            if (clearState) {
                this.videoService.clear();
                this.tagService.clear();
            }
        });
    }

    public ngOnInit(): void {
        this.getVideos();
        this.getTags();
    }

    public getVideos() {
        this.videoRepository
            .getVideos()
            .pipe(take(1))
            .subscribe((videos: any) => {
                this.videoService.setVideos(videos);
                this.videoService.setFilteredVideos(videos);
            });
    }

    public getTags() {
        this.tagRepository
            .getTags()
            .pipe(take(1))
            .subscribe((tags: any) => this.tagService.setTags(tags));
    }

    public ngAfterViewInit() {
        this.deviceService.setDevice({ height: window.innerHeight, width: window.innerWidth });
    }
}
