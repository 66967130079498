import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

import { IPoi } from "@models";

export enum ModalType {
    CARD = "card",
    FAVORITE_LIST = "favorite-list",
}

export const FAVORITE_ID = "favorite-modal-id";
export type FavoriteId = "favorite-modal-id";

export type ModalData = IPoi | null;

export interface IModalCreate {
    id: number | FavoriteId;
    data: ModalData;
}

@Injectable({ providedIn: "root" })
export class ModalManagerService {
    // this service is here to remove the cicular dependency between modal component and modal service
    // both modal component and modal service depend on modal manager to break the circle
    public modalCreate$: Observable<IModalCreate>;
    public modalRemove$: Observable<number | FavoriteId>;
    public modalClear$: Observable<boolean>;
    public modalToggle$: Observable<IModalCreate>;
    public modalFocus$: Observable<number | FavoriteId>;
    public modalToggleDrag$: Observable<number | FavoriteId>;
    public modalFavoriteOpened$: Observable<boolean>;

    private readonly modalCreateSource = new Subject<IModalCreate>();
    private readonly modalRemoveSource = new Subject<number | FavoriteId>();
    private readonly modalClearSource = new Subject<boolean>();
    private readonly modalToggleSource = new Subject<IModalCreate>();
    private readonly modalFocusSource = new Subject<number | FavoriteId>();
    private readonly modalToggleDragSource = new Subject<number | FavoriteId>();
    private readonly modalFavoriteOpenedSource = new BehaviorSubject<boolean>(false);

    constructor() {
        this.modalCreate$ = this.modalCreateSource.asObservable();
        this.modalRemove$ = this.modalRemoveSource.asObservable();
        this.modalClear$ = this.modalClearSource.asObservable();
        this.modalToggle$ = this.modalToggleSource.asObservable();
        this.modalFocus$ = this.modalFocusSource.asObservable();
        this.modalToggleDrag$ = this.modalToggleDragSource.asObservable();
        this.modalFavoriteOpened$ = this.modalFavoriteOpenedSource.asObservable();
    }

    public openCard(poi: IPoi): void {
        this.modalCreateSource.next({ id: poi.brickId, data: poi });
    }

    public close(modalId: number | FavoriteId): void {
        this.modalRemoveSource.next(modalId);

        if (ModalType.FAVORITE_LIST === getModalType(modalId)) {
            this.modalFavoriteOpenedSource.next(false);
        }
    }

    public focus(modalId: number | FavoriteId): void {
        this.modalFocusSource.next(modalId);
    }

    public toggleFavoriteList(): void {
        this.modalToggleSource.next({ id: FAVORITE_ID, data: null });
        this.modalFavoriteOpenedSource.next(!this.modalFavoriteOpenedSource.value);
    }

    public toggleDrag(modalId: number | FavoriteId): void {
        this.modalToggleDragSource.next(modalId);
    }

    public clearAll(): void {
        this.modalClearSource.next(true);
    }
}

export function getModalType(id: number | FavoriteId): ModalType {
    return id === FAVORITE_ID ? ModalType.FAVORITE_LIST : ModalType.CARD;
}

export function isPoiData(data: ModalData): data is IPoi {
    return (data as IPoi).brickId !== undefined;
}
