import { Injectable } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ICountry } from "@models/country/country";
import { CountryService } from "@models/country/country.service";

import { ModalManagerService } from "@core/modal/modal.manager.service";
import { ZoomService } from "@models/zoom/zoom.service";

import { IPlace, IPoi, isActivity, isCountry, isPlace, PoiType } from "@models/poi/poi";
import { PoiService } from "@models/poi/poi.service";

import { Itinerary, ItineraryService } from "@models/itinerary/itinerary.service";

@Injectable({ providedIn: "root" })
export class ShortcutService {
    public shortcutItems$: Observable<ICountry[] | IPoi[]>;

    public constructor(
        public readonly countryService: CountryService,
        public readonly itineraryService: ItineraryService,
        public readonly modalManagerService: ModalManagerService,
        public readonly poiService: PoiService,
        public readonly zoomService: ZoomService,
    ) {
        this.shortcutItems$ = combineLatest([
            this.countryService.countries$,
            this.itineraryService.itineraries$,
            this.poiService.pois$,
        ]).pipe(map(([countries, itineraries, pois]) => this.shortcutSmartItems(countries, itineraries, pois, 10)));

        this.shortcutItems$.subscribe();
    }

    public tapOnShortcutItem(item: ICountry | IPoi) {
        if (isCountry(item)) {
            this.itineraryService.addCountry(item);
            this.zoomService.zoomOnCountry(item);
        } else if (isPlace(item) || isActivity(item)) {
            this.zoomService.zoomOnPois([item]);
            this.modalManagerService.openCard(item);
            this.modalManagerService.focus(item.brickId);
        }
    }

    public shortcutSmartItems(
        countries: ICountry[],
        itineraries: Itinerary,
        pois: Map<number, IPoi[]>,
        limit: number = 4,
    ): ICountry[] | IPoi[] {
        // country from itineraries
        const countriesFromItineraries = new Array();
        let placesFromItineraries = new Array();

        if (itineraries.size > 0) {
            // foreach selected country of itineraries
            itineraries.forEach((places: IPlace[], country: ICountry) => {
                countriesFromItineraries.push(country.id);

                // if there is places keep them
                if (places.length > 0) {
                    placesFromItineraries = placesFromItineraries.concat(places.map((place) => place.brickId));
                }
            });

            let shortcutPois: IPoi[] = [];

            // get selected country length
            let size = Math.round(limit / itineraries.size);
            let counter = 0;

            // foreach country
            pois.forEach((countryPois: IPoi[], key: number) => {
                // take pois of that country
                let filteredPois = countryPois
                    .filter((poi: IPoi) => filteredSmartItems(poi, countriesFromItineraries, placesFromItineraries))
                    .map((fPoi) => this.addPlaceForPoi(fPoi));

                // sort them by popularity and get them by algo
                filteredPois = filteredPois.sort(orderByPopularity).slice(0, size);

                shortcutPois = shortcutPois.concat(filteredPois);

                if (filteredPois.length < size) {
                    let filteredPlaces = countryPois
                        .filter((poi: IPoi) =>
                            filteredSmartItems(poi, countriesFromItineraries, placesFromItineraries, true),
                        )
                        .map((fPoi) => this.addPlaceForPoi(fPoi));

                    filteredPlaces = filteredPlaces.sort(orderByPopularity).slice(0, limit - filteredPois.length);
                    shortcutPois = shortcutPois.concat(filteredPlaces);
                }

                // algo to match number of element
                counter = counter + size;
                if (counter - size > size) {
                    size = counter - size;
                }
            });

            return shortcutPois;
        }

        // by default return countries items, the top $limit
        let results = countries.filter((country) => !countriesFromItineraries.includes(country.id));
        results = results.sort(orderByPopularity).slice(0, limit);

        return results;
    }

    public addPlaceForPoi(poi: IPoi) {
        if (isActivity(poi) && !isPlace(poi)) {
            const places = this.poiService.getPlacesOfActivity(poi);

            poi.placesNames = places.length > 0 ? places.map((place) => place.title).join(" / ") : "";
        }

        return poi;
    }
}

function filteredSmartItems(poi: IPoi, countriesId: number[], placesId: number[], forceComplete: boolean = false) {
    // if places of selected get shortcut pois from places
    if (placesId.length > 0 && !forceComplete) {
        return (
            (poi.type === PoiType.ACCOMMODATION || poi.type === PoiType.EXPLORATION) &&
            placesId.includes(poi.placesIds[0])
        );
    }

    // if force complete is true get more place from countries and exclude included one
    if (forceComplete) {
        return (
            poi.type === PoiType.PLACE && countriesId.includes(poi.countryId) && !placesId.includes(poi.placesIds[0])
        );
    }

    return poi.type === PoiType.PLACE && countriesId.includes(poi.countryId);
}

function orderByPopularity(c1: ICountry | IPoi, c2: ICountry | IPoi) {
    return c1.popularity < c2.popularity ? 1 : -1;
}
