import { Injectable } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";

import { FilterType } from "@models/filter/filter";
import { FilterService } from "@models/filter/filter.service";
import { IPois, ItineraryPois } from "@models/itinerary-poi/itinerary-poi";
import { Itinerary, ItineraryService } from "@models/itinerary/itinerary.service";
import {
    IAccommodation,
    IExploration,
    IPoi,
    isAccommodation,
    isExploration,
    isPlace,
    uniquePois,
} from "@models/poi/poi";
import { PoiService } from "@models/poi/poi.service";
import { SearchResult, SearchService } from "@models/search/search.service";
import { IStack } from "@models/stack/stack";
import { StackService } from "@models/stack/stack.service";

@Injectable({ providedIn: "root" })
export class ItineraryPoiService {
    // itineraryPois$ contains the POIs to be displayed on the map
    // data structure : countryId -> { places, explorations, accommodations }
    public itineraryPois$: Observable<ItineraryPois>;
    // flattened version of itineraryPois$
    public itineraryPoisList$: Observable<IPoi[]>;
    // list of the pois actually rendered on the map

    public constructor(
        private readonly itineraryService: ItineraryService,
        private readonly filterService: FilterService,
        private readonly poiService: PoiService,
        private readonly searchService: SearchService,
        private readonly stackService: StackService,
    ) {
        // combine pois$ and itineraris$ to get the pois-to-display on the map, convert to ItineraryPois
        const poisToDisplay$ = this.itineraryService.itineraries$.pipe(
            switchMap((itineraries) =>
                this.poiService.pois$.pipe(
                    // don't emit if the pois of the country did not get retrieved via API yet
                    filter((poisMap) => hasCountryNotLoaded(itineraries, poisMap)),
                    map((poisMap) => mapItinerariesToItineraryPois(itineraries, poisMap)),
                ),
            ),
        );

        // get the stacked pois and convert to ItineraryPois
        const stackedPois$ = this.stackService.stacks$.pipe(map(mapStackedPoisToItineraryPois));

        // merge the pois-to-display and stacked pois
        const poisToDisplayWithStack$ = combineLatest([poisToDisplay$, stackedPois$]).pipe(
            map(([poisToDisplay, stackedPois]) => mergeItineraryPois(poisToDisplay, stackedPois)),
        );

        // if search is active : replace the poisToDisplayWithStack with the search result (converted to ItineraryPois)
        const poisToDisplayWithStackAndSearch$ = this.searchService.searchIsActive$.pipe(
            switchMap((isSearchActive) =>
                isSearchActive
                    ? // if search is active : display the search result
                      this.searchService.searchResult$.pipe(map(mapSearchResultToItineraryPois))
                    : // otherwise show pois-to-display and stacks
                      poisToDisplayWithStack$,
            ),
        );

        // last step : apply the filters
        this.itineraryPois$ = combineLatest([poisToDisplayWithStackAndSearch$, this.filterService.filters$]).pipe(
            map(([itineraryPois, filters]) => applyFiltersToItineraryPois(itineraryPois, filters)),
            shareReplay(1),
        );

        // get a flattened list of the itineraryPois Map
        this.itineraryPoisList$ = this.itineraryPois$.pipe(
            map((itineraryPois) =>
                Array.from(itineraryPois.values())
                    .map(({ places, explorations, accommodations }) => [...places, ...explorations, ...accommodations])
                    .flat(),
            ),
            shareReplay(1),
        );
    }
}

function mapSearchResultToItineraryPois(searchResult: SearchResult): ItineraryPois {
    return mapPoisToItineraryPois(searchResult[1]);
}

function mapPoisToItineraryPois(pois: IPoi[]): ItineraryPois {
    const itineraryPois = new Map<number, IPois>();
    pois.forEach((poi) => {
        // add the country to the Map if required
        if (!itineraryPois.has(poi.countryId)) {
            itineraryPois.set(poi.countryId, { places: [], explorations: [], accommodations: [] });
        }
        const countryPois = itineraryPois.get(poi.countryId);

        if (countryPois == null) {
            // should never happen, no typeguard on Map (https://github.com/microsoft/TypeScript/issues/9619)
            console.error(`mapSearchResultToItineraryPois - countryPois not found`);

            return;
        }

        // add the poi into the adequate array ("places" if IPlace, "explorations" if IExploration etc.)
        if (isPlace(poi)) {
            countryPois.places = [...countryPois.places, poi];
        }
        if (isExploration(poi)) {
            countryPois.explorations = [...countryPois.explorations, poi];
        }
        if (isAccommodation(poi)) {
            countryPois.accommodations = [...countryPois.accommodations, poi];
        }
    });

    return itineraryPois;
}

function mapItinerariesToItineraryPois(itineraries: Itinerary, poisMap: Map<number, IPoi[]>): ItineraryPois {
    const itineraryPois = new Map<number, IPois>();
    // for each itinerary
    itineraries.forEach((itineraryPlaces, country) => {
        // get the list of all pois for this country
        const countryPois = poisMap.get(country.id);
        if (countryPois == null) {
            // should never happen because the emission is filtered if a country doesn't have pois
            console.error(`PoiService - country pois not found`);

            return;
        }

        const itineraryPlaceIds = itineraryPlaces.map((place) => place.brickId);
        // display every places
        const places = countryPois.filter(isPlace);
        // display only explorations and accommodations of places in itinerary
        const explorations = getExplorationsToDisplay(countryPois, itineraryPlaceIds);
        const accommodations = getAccommodationsToDisplay(countryPois, itineraryPlaceIds);

        itineraryPois.set(country.id, { places, explorations, accommodations });
    });

    return itineraryPois;
}

function mapStackedPoisToItineraryPois(stacks: Map<number, IStack>): ItineraryPois {
    const allStackedPois = Array.from(stacks.values())
        .map((stack) => stack.stackedPois)
        .flat();

    return mapPoisToItineraryPois(uniquePois(allStackedPois));
}

function getExplorationsToDisplay(pois: IPoi[], itineraryPlaceIds: number[]) {
    const poisExplorations = pois.filter(isExploration);

    const explorations: IExploration[] = [];

    poisExplorations.forEach((poi) => {
        const included = poi.placesIds.filter((placeId) => itineraryPlaceIds.includes(placeId)).length > 0;

        if (included) {
            explorations.push(poi);
        }
    });

    return explorations;
}

function getAccommodationsToDisplay(pois: IPoi[], itineraryPlaceIds: number[]) {
    const poisAccommodations = pois.filter(isAccommodation);

    const accommodations: IAccommodation[] = [];

    poisAccommodations.forEach((poi) => {
        const included = poi.placesIds.filter((placeId) => itineraryPlaceIds.includes(placeId)).length > 0;

        if (included) {
            accommodations.push(poi);
        }
    });

    return accommodations;
}

function applyFiltersToItineraryPois(itineraryPois: ItineraryPois, filters: Set<FilterType>): ItineraryPois {
    const itineraryPoi = new Map<number, IPois>();
    Array.from(itineraryPois.entries()).forEach(([countryId, { places, explorations, accommodations }]) => {
        itineraryPoi.set(countryId, filterItineraryPoi({ places, explorations, accommodations }, filters));
    });

    return itineraryPoi;
}

function filterItineraryPoi({ places, explorations, accommodations }: IPois, filters: Set<FilterType>): IPois {
    if (!filters.size) {
        // no filter : send everything
        return { accommodations, explorations, places };
    }

    // at least one filter : send only active filters
    return {
        accommodations: filters.has(FilterType.ACCOMMODATION) ? accommodations : [],
        explorations: filters.has(FilterType.EXPLORATION) ? explorations : [],
        places: filters.has(FilterType.PLACE) ? places : [],
    };
}

function mergeItineraryPois(ip1: ItineraryPois, ip2: ItineraryPois): ItineraryPois {
    // initialize new map with values from ip1
    const itineraryPois = new Map(
        Array.from(ip1.entries()).map(([countryId, pois]) => [
            countryId,
            {
                accommodations: [...pois.accommodations],
                explorations: [...pois.explorations],
                places: [...pois.places],
            },
        ]),
    );

    // add values from ip2
    ip2.forEach((ip2Pois, countryId) => {
        let ip1Pois = itineraryPois.get(countryId);
        if (ip1Pois == null) {
            ip1Pois = { places: [], explorations: [], accommodations: [] };
        }

        // if ip1 and ip2 have pois in common : filter the duplicates
        itineraryPois.set(countryId, {
            accommodations: uniquePois([...ip1Pois.accommodations, ...ip2Pois.accommodations]),
            explorations: uniquePois([...ip1Pois.explorations, ...ip2Pois.explorations]),
            places: uniquePois([...ip1Pois.places, ...ip2Pois.places]),
        });
    });

    return itineraryPois;
}

function hasCountryNotLoaded(itineraries: Itinerary, poisMap: Map<number, IPoi[]>): boolean {
    return !Array.from(itineraries.keys()).some((country) => !poisMap.has(country.id));
}
