import { IPoi } from "@models/poi/poi";

export interface IUser {
    id: number;
    name: string;
    avatarUrl: string;
    // countryId -> navigations (IPoi)
    navigationHistory: Map<number, IPoi[]>;
    // countryId -> searches (string)
    searchHistory: Map<number, string[]>;
}

export function initUser(user: { id: number; name: string; avatarUrl: string }): IUser {
    return { ...user, navigationHistory: new Map<number, IPoi[]>(), searchHistory: new Map<number, string[]>() };
}

export function areSameUser(user1: IUser | null, user2: IUser | null): boolean {
    if (user1 == null && user2 == null) {
        return true;
    }
    if (user1 == null || user2 == null) {
        return false;
    }

    return user1.id === user2.id;
}

export function sortUsers(u1: IUser, u2: IUser) {
    return u1.name.localeCompare(u2.name, "fr");
}

export function setNavigationHistory(user: IUser, newNavigationHistory: IPoi[], countryId: number): IUser {
    const navigationHistory = new Map(user.navigationHistory).set(countryId, newNavigationHistory);

    return {
        ...user,
        navigationHistory,
    };
}

export function setSearchHistory(user: IUser, newSearchHistory: string[], countryId: number): IUser {
    const searchHistory = new Map(user.searchHistory).set(countryId, newSearchHistory);

    return {
        ...user,
        searchHistory,
    };
}
