import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { SearchService } from "../search/search.service";

import { IMenu, initialMenu, ListMode } from "./menu";

export interface IMenuPosition {
    x: number;
    y: number;
}

@Injectable({
    providedIn: "root",
})
export class MenuService {
    public menu$: Observable<IMenu>;
    public menuPosition$: Observable<IMenuPosition>;
    public menuMirrorPosition$: Observable<IMenuPosition>;
    public menuBoardIsDisplayed$: Observable<boolean>;

    public paletSize = 138;

    private readonly menuPositionSource = new BehaviorSubject<IMenuPosition>({ x: 0, y: 0 });
    private readonly menuMirrorPositionSource = new BehaviorSubject<IMenuPosition>({ x: 0, y: 0 });
    private readonly menuSource = new BehaviorSubject<IMenu>(initialMenu);
    private readonly menuBoardIsDisplayedSource = new BehaviorSubject<boolean>(false);

    private readonly parentX: number = 0;
    private readonly parentY: number = 0;

    public constructor(private readonly searchService: SearchService) {
        this.menu$ = this.menuSource.asObservable();
        this.menuPosition$ = this.menuPositionSource.asObservable();
        this.menuMirrorPosition$ = this.menuMirrorPositionSource.asObservable();
        this.menuBoardIsDisplayed$ = this.menuBoardIsDisplayedSource.asObservable();

        this.menuMirrorPosition$.subscribe((menuPosition: IMenuPosition) => {
            const updatedMenu = this.menuSource.getValue();
            // means palet position is on the left of the middle
            // palet position is on the right the middle
            updatedMenu.mirrorMode = menuPosition.x - this.paletSize / 2 > this.parentX / 2 ? true : false;
            this.menuSource.next(updatedMenu);
        });

        // get parent content size
        this.parentX = document.documentElement.clientWidth;
        this.parentY = document.documentElement.clientHeight;

        // get dynamic palet size
        const palet = document.getElementById("palet");
        if (palet != null) {
            this.paletSize = palet.clientWidth;
        }
    }

    public setMenu(menu: IMenu): void {
        this.menuSource.next({ ...menu });
    }

    public getInitMenuPosition(side: string) {
        const posOrigin = document.getElementsByClassName(`palet-container-${side}`);
        if (posOrigin.length > 0) {
            return posOrigin[0].getBoundingClientRect();
        }

        return null;
    }

    public initMenuPosition(area: string = "left") {
        const pos: any = this.getInitMenuPosition(area);

        if (pos != null) {
            this.setMenuPosition({ x: pos.x, y: pos.y });
        }
    }

    public getCurrentMirrorMode() {
        return this.menuSource.value.mirrorMode;
    }

    public setMenuPosition(position: IMenuPosition) {
        position.x -= this.paletSize / 2;
        position.y -= this.paletSize / 2;
        this.menuPositionSource.next(position);
        // set Mirror position on awake
        this.setMenuMirrorPosition(position);
    }

    public setMenuMirrorPosition(position: IMenuPosition) {
        this.menuMirrorPositionSource.next(position);
    }

    public closeMenu(): void {
        this.menuSource.next({ ...this.menuSource.value, status: false });

        // clear just in case
        if (!this.menuSource.value.status) {
            this.searchService.clear();
        }
    }

    public toggleMenu(): void {
        this.menuSource.next({ ...this.menuSource.value, status: !this.menuSource.value.status });

        this.searchService.clear();
    }

    public toggleListMode(): void {
        const listMode = this.menuSource.value.listMode === ListMode.ALL ? ListMode.RENDERED : ListMode.ALL;
        this.menuSource.next({ ...this.menuSource.value, listMode });
    }

    public toggleBoard(): void {
        this.menuBoardIsDisplayedSource.next(!this.menuBoardIsDisplayedSource.value);
    }
}
