// please remember to change the scss values if you change these
// packages/moustache-dali/src/styles/functions.scss
const TARGET_HEIGHT = 2160;
const TARGET_WIDTH = 3840;

export const initialDevice = {
    height: TARGET_HEIGHT,
    ratioH: 1,
    ratioW: 1,
    width: TARGET_WIDTH,
};
export interface IDevice {
    height: number;
    ratioH: number;
    ratioW: number;
    width: number;
}

export interface IDimensions {
    height: number;
    width: number;
}

export function isSameDevice(d1: IDevice, d2: IDevice): boolean {
    return d1.height === d2.height && d1.ratioH === d2.ratioH && d1.ratioW === d2.ratioW && d1.width === d2.width;
}

export function getDeviceFromDimensions(dimensions: IDimensions): IDevice {
    return {
        ...dimensions,
        ratioH: dimensions.height / TARGET_HEIGHT,
        ratioW: dimensions.width / TARGET_WIDTH,
    };
}
