import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { TripRepository } from "@api-binding/trip/trip.repository";

import { SlackService } from "@core/slack/slack.service";

import { ITrip } from "./trip";

@Injectable({
    providedIn: "root",
})
export class TripService {
    public constructor(
        private readonly slackService: SlackService,
        private readonly tripRepository: TripRepository,
    ) {}

    public save(trip: ITrip) {
        return this.tripRepository.save(trip);
    }

    public resendFailed() {
        // look for trips to send in localstorage
        if (null != localStorage.getItem("tripsToSend")) {
            const tripsToSend = JSON.parse(localStorage.getItem("tripsToSend") as string);

            if (null !== tripsToSend) {
                localStorage.removeItem("tripsToSend");

                tripsToSend.forEach((trip: ITrip) => {
                    this.slackService.notify(`Unable to send email`, trip);
                });
            }
        }
    }
}
