import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { httpInterceptorProviders } from "./interceptors/interceptors";

import {
    ApiService,
    CountryMapper,
    CountryRepository,
    MockModeService,
    PoiMapper,
    PoiRepository,
    RequestCache,
    SessionMapper,
    SessionRepository,
    TripMapper,
    TripRepository,
    UserMapper,
    UserRepository,
    TagMapper,
    TagRepository,
    VideoMapper,
    VideoRepository,
} from "./index";

@NgModule({
    imports: [HttpClientModule],
    providers: [
        ApiService,
        CountryMapper,
        CountryRepository,
        PoiMapper,
        PoiRepository,
        SessionMapper,
        SessionRepository,
        TripMapper,
        TripRepository,
        RequestCache,
        httpInterceptorProviders,
        MockModeService,
        UserMapper,
        UserRepository,
        TagMapper,
        TagRepository,
        VideoMapper,
        VideoRepository,
    ],
})
export class ApiBindingModule {}
