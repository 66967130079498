import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { BackButtonComponent } from "@app/shared/components/back-button/back-button.component";
import { ButtonComponent, DebugComponent, MinimizeComponent, SearchBarComponent, VideoComponent } from "./components";
import { ScrollSpyDirective } from "./directives";
import { AccentuatedPipe, EllipsisPipe, MapKeysPipe, SafeUrlPipe } from "./pipes";
import { InsurancesService } from "./services";

@NgModule({
    bootstrap: [],
    declarations: [
        AccentuatedPipe,
        ButtonComponent,
        EllipsisPipe,
        MapKeysPipe,
        DebugComponent,
        MinimizeComponent,
        SafeUrlPipe,
        SearchBarComponent,
        ScrollSpyDirective,
        VideoComponent,
        BackButtonComponent,
    ],

    exports: [
        AccentuatedPipe,
        ButtonComponent,
        EllipsisPipe,
        MapKeysPipe,
        DebugComponent,
        MinimizeComponent,
        SafeUrlPipe,
        SearchBarComponent,
        ScrollSpyDirective,
        VideoComponent,
        BackButtonComponent,
    ],

    imports: [CommonModule, ReactiveFormsModule, FormsModule],
    providers: [InsurancesService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
