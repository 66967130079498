export enum ListMode {
    ALL = "all",
    RENDERED = "rendered",
}

export interface IMenu {
    listMode: ListMode;
    mirrorMode: boolean;
    status: boolean;
}

export const initialMenu: IMenu = {
    listMode: ListMode.ALL,
    mirrorMode: false,
    status: false,
};
