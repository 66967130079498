import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

import { SessionRepository } from "@api-binding";
import { DEFAULT_ROUTE } from "@app/routes";
import { ModalManagerService } from "@core/modal/modal.manager.service";
import { FavoriteService } from "@models/favorite/favorite.service";
import { FilterService } from "@models/filter/filter.service";
import { ItineraryService } from "@models/itinerary/itinerary.service";
import { MapviewService } from "@models/mapview/mapview.service";
import { MenuService } from "@models/menu/menu.service";
import { PoiService } from "@models/poi/poi.service";
import { SearchService } from "@models/search/search.service";
import { ISession } from "@models/session/session";
import { UserService } from "@models/user/user.service";
import { ZoomService } from "@models/zoom/zoom.service";

@Injectable({
    providedIn: "root",
})
export class SessionService {
    public currentSession$: Observable<ISession | null>;
    private readonly currentSessionSource = new BehaviorSubject<ISession | null>(null);

    public constructor(
        private readonly sessionRepository: SessionRepository,
        private readonly filterService: FilterService,
        private readonly favoriteService: FavoriteService,
        private readonly itineraryService: ItineraryService,
        private readonly mapviewService: MapviewService,
        private readonly menuService: MenuService,
        private readonly modalManagerService: ModalManagerService,
        private readonly poiService: PoiService,
        private readonly searchService: SearchService,
        private readonly userService: UserService,
        private readonly zoomService: ZoomService,
        private readonly router: Router,
    ) {
        this.currentSession$ = this.currentSessionSource.asObservable();
    }

    public async create(session: ISession): Promise<ISession | null> {
        return this.sessionRepository.create(session).then((newSession) => {
            this.currentSessionSource.next(newSession);

            return newSession;
        });
    }

    public async end() {
        if (this.currentSessionSource.value != null) {
            await this.sessionRepository.end(this.currentSessionSource.value);
            this.currentSessionSource.next(null);
        }
    }

    public async endSession() {
        // clear session
        await this.end();

        // clear favoris
        this.favoriteService.clear();

        // clear itinerary
        this.itineraryService.clear();

        // init filters
        this.filterService.clear();

        // clear search
        this.searchService.clear();

        // init map zoom
        this.zoomService.zoomOnMapview(this.mapviewService.getInitMapview());

        // clear focus poi
        this.poiService.clearFocus();

        // clear all modal
        this.modalManagerService.clearAll();

        // clear user
        this.userService.clear();

        // close palet
        this.menuService.closeMenu();

        // redirect to login page
        this.router.navigate([DEFAULT_ROUTE]).catch((error) => {
            console.error(`Palet - routing error : ${error.message}`);
        });
    }
}
