import { Injectable } from "@angular/core";

import { ITripForSave } from "@dtos";
import { IPoi, ITrip } from "@models";

@Injectable()
export class TripMapper {
    public mapToPostTrip(trip: ITrip): ITripForSave {
        const brickIds = trip.pois.map((poi: IPoi) => poi.brickId);

        return {
            brickIds,
            email: trip.email,
            sessionId: trip.sessionId,
            wantsToBeContacted: trip.wantsToBeContacted,
        };
    }
}
