import { Injectable } from "@angular/core";
import Keyboard from "simple-keyboard";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { ConfigService } from "@app/config.service";
import { FAVORITE_ID, FavoriteId, ModalManagerService } from "@core/modal/modal.manager.service";
import { FilterService, IMenu, MenuService } from "@models";

const DISPATCHER = {
    FAV: "favoris--dispatcher",
    LOGIN: "login--dispatcher",
    PASSWORD: "password--dispatcher",
    MENU: "menu--dispatcher",
};

@Injectable({ providedIn: "root" })
export class KeyboardService {
    public value: string;
    public keyboard: Keyboard;

    public display = true;

    public activeDispatcher = DISPATCHER.MENU;

    public keyboardData$: Observable<string>;
    public keyboardEnterData$: Observable<boolean>;
    public keyboardFavData$: Observable<string>;
    public keyboardLoginData$: Observable<string>;

    private readonly keyboardDataSource = new BehaviorSubject<string>("");
    private readonly keyboardEnterData = new BehaviorSubject<boolean>(false);
    private readonly keyboardDataFavSource = new BehaviorSubject<string>("");
    private readonly keyboardDataLoginSource = new BehaviorSubject<string>("");

    constructor(
        private readonly filterService: FilterService,
        private readonly config: ConfigService,
        private readonly menuService: MenuService,
        private readonly modalService: ModalManagerService,
    ) {
        // create an observable for keyboard input
        this.keyboardData$ = this.keyboardDataSource.asObservable();
        this.keyboardFavData$ = this.keyboardDataFavSource.asObservable();
        this.keyboardEnterData$ = this.keyboardEnterData.asObservable();
        this.keyboardLoginData$ = this.keyboardDataLoginSource.asObservable();

        this.setAutoClose();
    }

    public setAutoClose() {
        // if palet is closing clear and hide the keyboard
        this.menuService.menu$.subscribe((menu: IMenu) => {
            this.reset();
        });
        // if any action on filters close keyboard
        this.filterService.filters$.subscribe((filters) => {
            this.reset();
        });
        // if any modal are close keyboard
        this.modalService.modalRemove$.subscribe((modal: number | FavoriteId) => {
            this.reset();
        });
        // if any modal are toggle hide keyboard
        this.modalService.modalToggle$.subscribe((modal) => {
            this.reset();
        });
        // if favoris modal get the focus
        this.modalService.modalFocus$.subscribe((modal: number | FavoriteId) => {
            if (modal === FAVORITE_ID) {
                this.activeDispatcher = DISPATCHER.FAV;
                this.clear();
            }
        });
    }

    public init() {
        this.destroy();

        this.keyboard = new Keyboard({
            ...this.config.frenchKeyboard,
            onChange: (input) => this.onChange(input),
            onKeyPress: (button) => this.onKeyPress(button),
        });
    }

    public reset() {
        this.activeDispatcher = DISPATCHER.MENU;
        this.clear();
        this.hide();
    }

    public destroy() {
        if (this.keyboard != null) {
            this.keyboard.destroy();
        }
    }

    public setMenuDispatcher() {
        this.activeDispatcher = DISPATCHER.MENU;
    }

    public setFavDispatcher() {
        this.activeDispatcher = DISPATCHER.FAV;
    }

    public setLoginDispatcher() {
        this.activeDispatcher = DISPATCHER.LOGIN;
    }

    public setPasswordDispatcher() {
        this.activeDispatcher = DISPATCHER.PASSWORD;
    }

    public setManualDispatcher(dispatcher: string) {
        this.activeDispatcher = dispatcher;
    }

    public setInput(input: string) {
        this.keyboard.setInput(input);
        this.keyboard.setCaretPosition(input.length);
    }

    public show() {
        this.display = true;
    }

    public hide() {
        this.display = false;
    }

    public clear() {
        if (this.keyboard != null) {
            this.keyboardDataLoginSource.next("");
            this.keyboard.clearInput();
        }
    }

    public onChange = (input: string) => {
        this.value = input;
        this.dispatchManager(input);
    };

    public onKeyPress = (button: string) => {
        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === "{enter}") {
            this.keyboardEnterData.next(true);
        } else if (button === "{shift}" || button === "{lock}") {
            this.handleShift();
        } else if (button === "{clear}") {
            // custom button to clear the input
            this.dispatchManager("");
            this.clear();
        } else if (button === "{hide}") {
            // custom button to hide
            this.hide();
        }
    };

    protected dispatchManager(input: string) {
        if (this.activeDispatcher === DISPATCHER.MENU) {
            this.keyboardDataSource.next(input);
        } else if (this.activeDispatcher === DISPATCHER.FAV) {
            this.keyboardDataFavSource.next(input);
        } else {
            this.keyboardDataLoginSource.next(input);
        }
    }

    protected handleShift = () => {
        const currentLayout = this.keyboard.options.layoutName;
        const shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboard.setOptions({
            layoutName: shiftToggle,
        });
    };
}
