import { Injectable } from "@angular/core";

import { ISessionForSave, ISessionForUpdate, ISessionGetDto } from "@dtos";
import { ISession } from "@models";

@Injectable()
export class SessionMapper {
    public mapPostResult = (sessionDto: ISessionForSave): ISession => {
        return { id: sessionDto.id, userId: sessionDto.userId };
    };

    public mapToPostSession = (session: ISession): ISessionForSave => {
        return { userId: session.userId };
    };

    public mapToPatchSession = (session: ISession): ISessionForUpdate => {
        return { endedAt: endSessionTime() };
    };
}

function endSessionTime() {
    const d = new Date();
    const date = d.toISOString().split("T")[0];
    const time = d.toTimeString().split(" ")[0];

    return `${date} ${time}`;
}
