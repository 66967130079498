import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { EmailService } from "@models/email/email.service";

export enum Panel {
    PLACE = "Lieux",
    EXPLORATION = "Activités",
    ACCOMMODATION = "Hôtels",
}

export interface IFavoriteList {
    panel: Panel;
}

const initialFavoriteListe: IFavoriteList = {
    panel: Panel.PLACE,
};

@Injectable({
    providedIn: "root",
})
export class FavoriteListService {
    public favoriteList$: Observable<IFavoriteList>;

    public favListIsEditable$: Observable<boolean>;
    private readonly favoriteListSource = new BehaviorSubject<IFavoriteList>(initialFavoriteListe);
    private readonly favListIsEditableSource = new BehaviorSubject<boolean>(false);

    public constructor(private readonly emailService: EmailService) {
        this.favoriteList$ = this.favoriteListSource.asObservable();
        this.favListIsEditable$ = this.favListIsEditableSource.asObservable();
    }

    public setPanel(panel: Panel): void {
        if (this.favoriteListSource.value.panel === panel) {
            return;
        }

        this.favoriteListSource.next({ ...this.favoriteListSource.value, panel });
    }

    public closeEditMode() {
        this.emailService.closeForm();
        this.favListIsEditableSource.next(false);
    }

    public toggleEditMode() {
        this.favListIsEditableSource.next(!this.favListIsEditableSource.value);
    }
}
