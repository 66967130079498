import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { PopinService } from "../popin.service";

@Component({
    selector: "mv-popin",
    styleUrls: ["./popin.component.scss"],
    templateUrl: "./popin.component.html",
})
export class PopinComponent {
    constructor(
        public sanitizer: DomSanitizer,
        public readonly popinService: PopinService,
    ) {}

    public confirmPopin() {
        this.popinService.confirmAction();
    }

    public cancelPopin() {
        this.popinService.cancelAction();
    }
}
