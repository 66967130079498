import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DoubleTapDirective, LongPressDirective, TapDirective } from "./index";
import { InteractionDebugComponent } from "./interaction-debug/interaction-debug.component";
import { InteractionService } from "./interaction.service";

@NgModule({
    declarations: [InteractionDebugComponent, LongPressDirective, TapDirective, DoubleTapDirective],
    exports: [InteractionDebugComponent, LongPressDirective, TapDirective, DoubleTapDirective],
    imports: [CommonModule],
    providers: [InteractionService],
})
export class InteractionModule {}
