import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "accentuated",
    pure: true,
})
export class AccentuatedPipe implements PipeTransform {
    public transform(accentuatedText: string): string {
        return accentuatedText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
}
