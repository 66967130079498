import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
    exports: [MatSnackBarModule],
    imports: [MatSnackBarModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MaterialModule {}
